import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Component from '../modules/component/pages/Component';
import CreateComponent from '../modules/component/pages/addComponent';
import Prototype from '../modules/prototype/pages/Prototype';
import CreatePrototype from '../modules/prototype/pages/addPrototype';
import CreatePrototypePage from '../modules/prototype/pages/createPage';
import CreatePage from '../modules/page/pages/createPage';
import EditPage from '../modules/prototype/pages/editPage';
import SearchComponent from '../modules/prototype/pages/searchComponent';
import SearchPages from '../modules/prototype/pages/searchPages';
import SKUGenerator from '../modules/skuGenerator/pages/skuGenerator';
import Login from '../modules/login/pages/login';
import GoogleAuthSuccessPage from '../modules/google-auth/successPage';
import Settings from '../modules/settings/pages/Settings';
import ReferenceScreensList from '../modules/ref-screens/pages/list';
import MoodboardReferencesList from '../modules/moodboard-references/pages/list';
import AuthRoute from './AuthRoute';
import MoodboardConfig from '../modules/Moodboard-config/pages/moodboard-list';
import CreatePageMoodboard from '../modules/Moodboard-config/pages/createPage';
import EditPageMoodboard from '../modules/Moodboard-config/pages/editPage';

const Routes = (): JSX.Element => (
  <Router>
    <Switch>
      <Route exact path="/" component={Login} />
      <AuthRoute exact path="/components" component={Component} />
      <AuthRoute exact path="/components/:cId" component={Component} />
      <AuthRoute
        exact
        path="/component/create/:componentId?"
        component={CreateComponent}
      />
      <AuthRoute exact path="/prototypes" component={Prototype} />
      <AuthRoute exact path="/moodgen" component={MoodboardConfig} />
      <AuthRoute
        exact
        path="/prototype/create/:prototypeId?/:view?"
        component={CreatePrototype}
      />
      <AuthRoute
        exact
        path="/prototype/view/:prototypeId?vew"
        component={CreatePrototype}
      />
      <AuthRoute
        exact
        path="/prototype/create-page/:prototypeId/:prototypeName"
        component={CreatePrototypePage}
      />
      <AuthRoute exact path="/page/create" component={CreatePage} />
      <AuthRoute
        exact
        path="/moodgen/create-page/:prototypeId/:prototypeName"
        component={CreatePageMoodboard}
      />
      <AuthRoute
        exact
        path="/prototype/edit-page/:prototypeId/:pageId"
        component={EditPage}
      />
      <AuthRoute
        exact
        path="/moodgen/edit-page/:prototypeId/:pageId"
        component={EditPageMoodboard}
      />

      <AuthRoute
        exact
        path="/prototype/component/search/:prototypeId/:pageId"
        component={SearchComponent}
      />
      <AuthRoute
        exact
        path="/prototype/page/search/:prototypeId/:pageId"
        component={SearchPages}
      />
      <AuthRoute
        exact
        path="/prototype/page/search/component/:prototypeId/:pageId"
        component={Component}
      />
      <AuthRoute exact path="/generate-sku" component={SKUGenerator} />
      <AuthRoute
        exact
        path="/google-auth-success"
        component={GoogleAuthSuccessPage}
      />
      <AuthRoute
        exact
        path="/reference-screens"
        component={ReferenceScreensList}
      />
      <AuthRoute
        exact
        path="/moodgen-references"
        component={MoodboardReferencesList}
      />
      <AuthRoute exact path="/settings" component={Settings} />
    </Switch>
  </Router>
);

export default Routes;
