const config = {
  api: {
    BASE_URL: process.env.REACT_APP_API_BASE_URL,
    MEDIA_URL: null,
    API_PREFIX: process.env.REACT_APP_API_PREFIX,
  },
  s3: {
    ENDPOINT: process.env.REACT_APP_BUCKET_ENDPOINT,
  },
  themes: {
    dark: {
      '--th-primary-color': '#161621',
      '--th-secondary-color': 'rgba(49,58,77,0.49)',
      '--th-tertiary-color': '#2B57A4',
      '--th-primary-color-alt': 'rgba(255,255,255,0.87)',
      '--th-secondary-color-alt': 'rgba(255,255,255,0.38)',
      '--th-tertiary-color-alt': 'rgba(255,255,255,0.87)',
      '--th-primary-dark': '#000',
      '--th-shadow': 'none',
      '--th-shadow-hover': 'none',
      '--th-sidebar-background-color': '#250241',
      '--th-invert-color': 'invert(1)',
      '--th-light-blue': '#309dd3',
      '--th-icon-edit': '-67px -3px',
      '--th-icon-delete': '-45px -3px',
      '--th-form-box-shadow': 'none',
      '--th-brightness': 'brightness(10)',
      '--select-box-shadow': 'none',
      '--th-light-grey': '#7c7c7c',
      '--th-dark-grey': '#61666A',
      '--th-light-border': '#c7c7c7',
      '--th-page-overlay': '#000000b5',
      '--th-border-dark': '#2b333b',
      '--th-element-bg': '#3C3C4E',
      '--th-bg-color': '#212130',
      '--th-breadcrumb-color': '#ffffff',
      '--th-ref-overlay': '#00000094',
      '--th-text-white': '#fff',
      '--th-bg-grayed': '#222c3a',
      '--th-primary-hover': '#460e85',
      '--th-tag-text': '#979797',
      '--th-invert-icon': 'none',
      '--th-page-bg': '#161621',
      '--th-post-border': 'rgba(49,58,77,0.49)',
      '--th-white-invet': '#ffffff',
      '--th-opacity': '1',
      '--th-element-bg-light': '#3C3C4E',
      '--th-primary-light': '#6B00DF',
      '--th-white-gray': '#ffffff',
      '--th-primary-dark-alt': '#4E008E',
      '--th-secondary-light': '#1C272E',
      '--th-status-text': '#ffffff',
      '--th-status-hover': '#67677f',
      '--th-by-badge': '#89949f',
      '--th-section-border': 'rgba(255,255,255,0.08)',
    },
    light: {
      '--th-primary-color': '#F8F8F8',
      '--th-secondary-color': '#ffffff',
      '--th-tertiary-color': '#E7E7E7',
      '--th-primary-color-alt': '#141B2B',
      '--th-secondary-color-alt': '#979797',
      '--th-tertiary-color-alt': '#000000',
      '--th-primary-dark': '#000',
      '--th-shadow': '0 2px 11px 0 rgba(205,203,203,0.5)',
      '--th-shadow-hover': '0 13px 20px 0 rgba(205,203,203,0.4)',
      '--th-sidebar-background-color': '#250241',
      '--th-invert-color': 'none',
      '--th-light-blue': '#2b57a4',
      '--th-icon-edit': '-24px -3px',
      '--th-icon-delete': '-3px -3px',
      '--th-form-box-shadow': '0 2px 11px 0 rgba(205, 203, 203, 0.5)',
      '--select-box-shadow': '0 2px 11px 0 rgba(190, 190, 190, 0.5)',
      '--th-brightness': 'brightness(0)',
      '--th-light-grey': '#7c7c7c',
      '--th-dark-grey': '#61666A',
      '--th-light-border': '#c7c7c7',
      '--th-page-overlay': '#000000b5',
      '--th-border-dark': '#dee2e6',
      '--th-element-bg': '#E7E7EA',
      '--th-bg-color': '#ffffff',
      '--th-breadcrumb-color': '#1C272E',
      '--th-ref-overlay': '#00000094',
      '--th-text-white': '#fff',
      '--th-bg-grayed': '#fff',
      '--th-primary-hover': '#360172',
      '--th-tag-text': 'rgba(255,255,255,0.9)',
      '--th-invert-icon': 'invert(1)',
      '--th-page-bg': '#ffffff',
      '--th-post-border': '#e7e7eA',
      '--th-white-invet': '#89949f',
      '--th-opacity': '.6',
      '--th-element-bg-light': '#EFF0F2',
      '--th-primary-light': '#6B00DF',
      '--th-white-gray': '#EFF0F2',
      '--th-primary-dark-alt': '#4E008E',
      '--th-secondary-light': '#1C272E',
      '--th-status-text': '#000000',
      '--th-status-hover': '#f3f3f3',
      '--th-by-badge': '#89949f',
      '--th-section-border': 'rgba(0,0,0,0.08)',
    },
  },
};

export default {
  ...config,
};
