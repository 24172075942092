import axiosApi from './axiosApi';

const requestComponents = async (params: {}) => {
  return axiosApi.post('components/search', { ...params });
};

const requestComponentLists = (
  project: string | null | number,
): Promise<any> => {
  if (project) {
    return axiosApi.get(`components/types?project=${project}`);
  }
  return axiosApi.get('components/types');
};

const upload = async (
  params: {} | undefined,
  fileType: string,
  componentId: string,
) => {
  return axiosApi.post(
    `components/upload?fileType=${fileType}&componentId=${componentId}`,
    params,
  );
};

const save = async (params: {}) => {
  return axiosApi.post('components/create', { ...params });
};

const saveRevisionOrReferenceComponent = async (params: {}) => {
  return axiosApi.post('components/create/revision_or_reference', {
    ...params,
  });
};

const requestComponentById = (id: string): Promise<any> => {
  return axiosApi.get(`components/${id}`);
};

const componetUsedPageList = async (params: {}) => {
  return axiosApi.post('components/usedList', { ...params });
};

const relatedComponetList = async (params: {}) => {
  return axiosApi.post('components/relatedList', { ...params });
};

const componentApi = {
  requestComponentLists,
  requestComponents,
  upload,
  save,
  requestComponentById,
  saveRevisionOrReferenceComponent,
  componetUsedPageList,
  relatedComponetList,
};

export default componentApi;
