/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars, jsx-a11y/no-static-element-interactions, no-console, no-nested-ternary, jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import prototypeApi from '../../../api/prototypeApi';
import FormikControl from '../../../components/common/FormikControl';
import TextError from '../../../components/common/TextError';
import SelectLists from '../data';
import DragAndDropField from '../../../components/FormFields/DragAndDropField';
import FloatBtnGroup from '../../../components/common/FloatBtnGroup';
import FloatBtn from '../../../components/common/FloatBtn';

const AddPrototype: React.FC<any> = (props: {
  match: {
    params: { prototypeId: string; view: boolean };
  };
  history: any;
}) => {
  const {
    match: {
      params: { prototypeId, view },
    },
    history,
  } = props;

  const [isUpload, setIsUpload] = useState<boolean>(true);
  const [prototypeView, setPrototypeView] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<{
    name: string | any;
    image: string | any;
    tier: string | any;
    stage: string | any;
    platform: string | any;
    strategySessionDate: Date | any;
    cms: string | any;
    launchDate: Date | any;
    hardLaunchDate: boolean;
    estDevStart: Date | any;
    comment: string | any;
    projectSOW: string | any;
    designSpec: string | any;
    techSpec: string | any;
    USPM: string | any;
    SLPM: string | any;
    BA: string | any;
  }>({
    name: '',
    image: '',
    tier: '',
    stage: '',
    strategySessionDate: '',
    platform: '',
    cms: '',
    launchDate: '',
    hardLaunchDate: false,
    estDevStart: '',
    comment: '',
    projectSOW: '',
    designSpec: '',
    techSpec: '',
    USPM: '',
    SLPM: '',
    BA: '',
  });

  const onSubmit = async (values: any, submitProps: any) => {
    const {
      image,
      name,
      tier,
      stage,
      strategySessionDate,
      platform,
      cms,
      launchDate,
      hardLaunchDate,
      estDevStart,
      comment,
      projectSOW,
      designSpec,
      techSpec,
      USPM,
      SLPM,
      BA,
    } = values;
    const submitData: any = {
      name,
      image,
      tier: typeof tier === 'object' ? tier.value : tier,
      stage: typeof stage === 'object' ? stage.value : stage,
      strategySessionDate,
      platform,
      cms: typeof cms === 'object' ? cms.value : cms,
      launchDate,
      hardLaunchDate,
      estDevStart,
      comment,
      projectSOW,
      designSpec,
      techSpec,
      USPM: typeof USPM === 'object' ? USPM.value : USPM,
      SLPM: typeof SLPM === 'object' ? SLPM.value : SLPM,
      BA: typeof BA === 'object' ? BA.value : BA,
    };
    if (prototypeId) {
      submitData.prototypeId = prototypeId;
    }
    let result;
    try {
      result = await prototypeApi.save(submitData);
    } catch (error) {
      toast(
        typeof error.response?.data?.message === 'string'
          ? error.response?.data?.message
          : 'Sorry. Error ocurred while processing request.',
        {
          type: 'error',
        },
      );
      return;
    }
    toast(
      prototypeId
        ? 'Prototype has been updated successfully'
        : 'Prototype has been created successfully',
      {
        type: 'success',
        autoClose: 3,
      },
    );

    submitProps.setSubmitting(false);
    submitProps.resetForm();
    if (!prototypeId) {
      history.push(
        `/prototype/create-page/${result.data.data.id}/${values.name}`,
      );
    } else {
      setPrototypeView(true);
      history.push(`/prototypes`);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Project name Required'),
    image: Yup.string().optional(),
    tier: Yup.string().required('Project tier Required'),
    stage: Yup.string().required('Project stage Required'),
    strategySessionDate: Yup.date()
      .nullable()
      .optional()
      .label('Strategy session date'),
    platform: Yup.string().optional(),
    cms: Yup.string().optional(),
    launchDate: Yup.date().nullable().optional().label('Launch date'),
    hardLaunchDate: Yup.boolean().optional().label('Hard launch date'),
    estDevStart: Yup.date().nullable().optional().label('Est. dev start'),
    comment: Yup.string().optional(),
    projectSOW: Yup.string().optional().url().label('Project SOW'),
    designSpec: Yup.string().optional().url().label('Design spec'),
    techSpec: Yup.string().optional().url().label('Tech spec'),
    USPM: Yup.string().optional(),
    SLPM: Yup.string().optional(),
    BA: Yup.string().optional(),
  });

  const handleUploadFile = useCallback(
    async (file) => {
      setIsUpload(false);
      const fileType = '';

      if (!file) return;

      const form = new FormData();
      form.append('image', file);

      const uploadName = await prototypeApi.upload(form, fileType);
      setIsUpload(true);

      setInitialValues({
        ...initialValues,
        image: uploadName.data.data,
      });
    },
    [initialValues, setInitialValues],
  );

  const setValue = useCallback(
    (options, values, element) => {
      const {
        image,
        name,
        tier,
        stage,
        strategySessionDate,
        platform,
        cms,
        launchDate,
        hardLaunchDate,
        estDevStart,
        comment,
        projectSOW,
        designSpec,
        techSpec,
        USPM,
        SLPM,
        BA,
      } = values;

      const prototype: { [key: string]: string | any } = {
        name,
        image,
        tier,
        stage,
        strategySessionDate,
        platform,
        cms,
        launchDate,
        hardLaunchDate,
        estDevStart,
        comment,
        projectSOW,
        designSpec,
        techSpec,
        USPM,
        SLPM,
        BA,
      };
      if (options) {
        prototype[element] = options;
        setInitialValues({ ...initialValues, ...prototype });
      } else {
        prototype[element] = '';
        setInitialValues({ ...initialValues, ...prototype });
      }
    },
    [initialValues, setInitialValues],
  );

  const getPrototype = async (id: string) => {
    try {
      const results = await prototypeApi.requestPrototypeById(id);
      const {
        image,
        name,
        tier,
        stage,
        strategySessionDate,
        platform,
        cms,
        launchDate,
        hardLaunchDate,
        estDevStart,
        comment,
        projectSOW,
        designSpec,
        techSpec,
        USPM,
        SLPM,
        BA,
      } = results.data.prototype;
      setInitialValues({
        image,
        name,
        tier: { value: tier, label: tier },
        stage: { value: stage, label: stage },
        strategySessionDate,
        platform,
        cms: cms ? { value: cms, label: cms } : '',
        launchDate,
        hardLaunchDate,
        estDevStart,
        comment,
        projectSOW,
        designSpec,
        techSpec,
        USPM: USPM ? { value: USPM, label: USPM } : '',
        SLPM: SLPM ? { value: SLPM, label: SLPM } : '',
        BA: BA ? { value: BA, label: BA } : '',
      });
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong!');
      }
    }
  };
  const pageEdit = () => {
    setPrototypeView(false);
    history.push(`/prototype/create/${prototypeId}/`);
  };
  const cancelEdit = () => {
    setPrototypeView(true);
    history.push(`/prototypes`);
  };
  useEffect(() => {
    if (prototypeId) {
      getPrototype(prototypeId);
    }
    if (view) {
      setPrototypeView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ar-common-content">
      <Row>
        <Col className="sticky-top">
          <div className="ar-common-breadcrumb">
            <button
              className="ar-common-breadcrumb__link"
              type="button"
              onClick={() => {
                history.goBack();
              }}
            >
              Prototypes
            </button>
            <FontAwesomeIcon icon={faChevronRight} />
            <span className="ar-common-breadcrumb__page">
              {prototypeView
                ? 'View Prototype'
                : prototypeId
                ? 'Edit Details'
                : 'Create Prototype'}
            </span>
          </div>
        </Col>{' '}
        <Col lg="12">
          <div className="ar-common-form ar-common-form--fixed-max-width">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(formik) => {
                return (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                  >
                    <FloatBtnGroup>
                      {prototypeId ? (
                        prototypeView ? (
                          <FloatBtn
                            id="edit"
                            onClickHandler={pageEdit}
                            variant="update"
                            title="Save"
                            type="button"
                          />
                        ) : (
                          <>
                            <FloatBtn
                              variant="cancel"
                              name="cancel"
                              disabled={!isUpload}
                              onClickHandler={cancelEdit}
                              title="Back"
                              type="button"
                            />
                            <FloatBtn
                              id="update"
                              variant="update"
                              type="submit"
                              name="update"
                              disabled={!isUpload}
                              title="Save"
                            />
                          </>
                        )
                      ) : (
                        <>
                          <FloatBtn
                            variant="cancel"
                            name="cancel"
                            disabled={!isUpload}
                            onClickHandler={cancelEdit}
                            title="Back"
                            type="button"
                          />
                          <FloatBtn
                            id="update"
                            variant="update"
                            type="submit"
                            name="save"
                            disabled={!isUpload}
                            onClickHandler={formik.handleSubmit}
                            title="Save"
                          />
                        </>
                      )}
                    </FloatBtnGroup>

                    <Row>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group">
                          <FormikControl
                            control="Input"
                            type="text"
                            name="name"
                            label="Project Name*"
                            disabled={prototypeView}
                          />
                        </div>
                      </Col>
                      {prototypeId && (
                        <Col xs="10" md="6">
                          <div className="ar-common-form__group">
                            <DragAndDropField
                              previewStatus
                              className="ar-common-form__select"
                              uploading={!isUpload}
                              currentFileName={
                                formik.values.image ? formik.values.image : ''
                              }
                              name="image"
                              label="Cover Image"
                              validFileTypes={['png', 'jpg', 'jpeg']}
                              fileType="image"
                              handleUploadFile={(event: any) => {
                                handleUploadFile(event);
                              }}
                            />
                          </div>
                        </Col>
                      )}
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <label htmlFor="tier">Tier*</label>
                          <CreatableSelect
                            className="ar-common-form__select"
                            isClearable
                            classNamePrefix="rctslct"
                            name="tier"
                            inputId="tier"
                            options={SelectLists.tierList}
                            placeholder="tier"
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            onChange={(option) => {
                              setValue(option, formik.values, 'tier');
                            }}
                            backspaceRemovesValue={false}
                            value={formik.values.tier}
                            noOptionsMessage={() => 'No options available'}
                            isDisabled={prototypeView}
                          />
                          <ErrorMessage name="tier" component={TextError} />
                        </div>
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <label htmlFor="stage">Stage*</label>
                          <CreatableSelect
                            className="ar-common-form__select"
                            isClearable
                            classNamePrefix="rctslct"
                            name="stage"
                            inputId="stage"
                            options={SelectLists.stageList}
                            placeholder="stage"
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            onChange={(option) => {
                              setValue(option, formik.values, 'stage');
                            }}
                            backspaceRemovesValue={false}
                            value={formik.values.stage}
                            noOptionsMessage={() => 'No options available'}
                            isDisabled={prototypeView}
                          />
                          <ErrorMessage name="stage" component={TextError} />
                        </div>
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <FormikControl
                            label="Strategy Session Date"
                            control="DatePicker"
                            placeholder="select..."
                            name="strategySessionDate"
                            value={formik.values.strategySessionDate}
                            onChange={(v: any) => {
                              setValue(v, formik.values, 'strategySessionDate');
                            }}
                            disabled={prototypeView}
                          />
                        </div>
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <FormikControl
                            control="Input"
                            type="text"
                            setFieldValue={formik.setFieldValue}
                            name="platform"
                            label="Platform"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={prototypeView}
                          />
                        </div>
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <label htmlFor="cms">CMS</label>
                          <CreatableSelect
                            className="ar-common-form__select"
                            isClearable
                            classNamePrefix="rctslct"
                            name="cms"
                            inputId="cms"
                            options={SelectLists.cmsList}
                            placeholder="select..."
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            onChange={(option) => {
                              setValue(option, formik.values, 'cms');
                            }}
                            backspaceRemovesValue={false}
                            value={formik.values.cms}
                            noOptionsMessage={() => 'No options available'}
                            isDisabled={prototypeView}
                          />
                          <ErrorMessage name="cms" component={TextError} />
                        </div>
                      </Col>
                      <Col xs="10" md="12">
                        <div className="ar-common-form__group form-group">
                          <FormikControl
                            control="ToggleButton"
                            type="checkbox"
                            setFieldValue={formik.setFieldValue}
                            name="hardLaunchDate"
                            label="Hard launch Date"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={prototypeView}
                          />
                        </div>
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <FormikControl
                            label="Launch Date"
                            control="DatePicker"
                            placeholder="select..."
                            name="launchDate"
                            value={formik.values.launchDate}
                            onChange={(v: any) => {
                              setValue(v, formik.values, 'launchDate');
                            }}
                            disabled={prototypeView}
                          />
                        </div>
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <FormikControl
                            label="Est Dev Start"
                            control="DatePicker"
                            placeholder="select..."
                            name="estDevStart"
                            value={formik.values.estDevStart}
                            onChange={(v: any) => {
                              setValue(v, formik.values, 'estDevStart');
                            }}
                            disabled={prototypeView}
                          />
                        </div>
                      </Col>
                      <Col xs="10" md="12">
                        <div className="ar-common-form__group form-group">
                          <FormikControl
                            control="Textarea"
                            type="textarea"
                            setFieldValue={formik.setFieldValue}
                            name="comment"
                            label="Comment"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={prototypeView}
                          />
                        </div>
                      </Col>
                      <Col xs="10" md="12">
                        <hr />
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <FormikControl
                            control="Input"
                            type="url"
                            setFieldValue={formik.setFieldValue}
                            name="projectSOW"
                            label="Project SOW"
                            url={formik.values.projectSOW}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={prototypeView}
                          />
                        </div>
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <FormikControl
                            control="Input"
                            type="url"
                            setFieldValue={formik.setFieldValue}
                            name="designSpec"
                            label="Design Spec"
                            url={formik.values.designSpec}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={prototypeView}
                          />
                        </div>
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <FormikControl
                            control="Input"
                            type="url"
                            setFieldValue={formik.setFieldValue}
                            name="techSpec"
                            label="Tech Spec"
                            url={formik.values.techSpec}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={prototypeView}
                          />
                        </div>
                      </Col>
                      <Col xs="10" md="10">
                        <hr />
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <label htmlFor="US PM">US PM</label>
                          <CreatableSelect
                            className="ar-common-form__select"
                            isClearable
                            classNamePrefix="rctslct"
                            name="USPM"
                            inputId="USPM"
                            options={SelectLists.uspmList}
                            placeholder="select..."
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            onChange={(option) => {
                              setValue(option, formik.values, 'USPM');
                            }}
                            backspaceRemovesValue={false}
                            value={formik.values.USPM}
                            noOptionsMessage={() => 'No options available'}
                            isDisabled={prototypeView}
                          />
                          <ErrorMessage name="USPM" component={TextError} />
                        </div>
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <label htmlFor="SL PM">SL PM</label>
                          <CreatableSelect
                            className="ar-common-form__select"
                            isClearable
                            classNamePrefix="rctslct"
                            name="SLPM"
                            inputId="SLPM"
                            options={SelectLists.slpmList}
                            placeholder="select..."
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            onChange={(option) => {
                              setValue(option, formik.values, 'SLPM');
                            }}
                            backspaceRemovesValue={false}
                            value={formik.values.SLPM}
                            noOptionsMessage={() => 'No options available'}
                            isDisabled={prototypeView}
                          />
                          <ErrorMessage name="SLPM" component={TextError} />
                        </div>
                      </Col>
                      <Col xs="10" md="6">
                        <div className="ar-common-form__group form-group">
                          <label htmlFor="BA">BA</label>
                          <CreatableSelect
                            className="ar-common-form__select"
                            isClearable
                            classNamePrefix="rctslct"
                            name="BA"
                            inputId="BA"
                            options={SelectLists.baList}
                            placeholder="select..."
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            onChange={(option) => {
                              setValue(option, formik.values, 'BA');
                            }}
                            backspaceRemovesValue={false}
                            value={formik.values.BA}
                            noOptionsMessage={() => 'No options available'}
                            isDisabled={prototypeView}
                          />
                          <ErrorMessage name="BA" component={TextError} />
                        </div>
                      </Col>
                    </Row>
                  </form>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddPrototype;
