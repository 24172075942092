/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import Slider from 'react-slick';
import moment from 'moment';
import componentApi from '../../../api/componentApi';
import { getSanitizedS3BucketUrl } from '../../../helpers';
import defaultComponentCover from '../../../assets/images/default-component.png';

const DetailPopup = (props: any) => {
  const { detailsModal, detailsToggle, componentId, selectPage } = props;

  let pagesList: any = null;
  let relatedList: any = null;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const s3BucketUrl = getSanitizedS3BucketUrl();

  const formatImageUrl = (url: string) =>
    url && url[0] === '/' ? url.substring(1, url.length) : url;

  const [componentData, setComponentData] = useState<any>(null);
  const [usedPages, setUsedPages] = useState([]);
  const [relatedComponets, setRelatedComponets] = useState([]);

  const [usedPagesTotalCount, setUsedPagesTotalCount] = useState(1);
  const [currentUsedPage, setCurrentUsedPage] = useState(1);

  const [relatedTotalCount, setRelatedTotalCount] = useState(1);
  const [currentRelatedPage, setCurrentRelatedPage] = useState(1);

  const getComponent = async (id: string) => {
    try {
      const results = await componentApi.requestComponentById(id);
      const data = {
        ...results.data.component,
        types: results.data.component.componentTypes.length
          ? results.data.component.componentTypes
          : [] || [results.data.component.componentType],
      };
      [
        'wireFrameSketch',
        'wireFrameImage',
        'designImage',
        'mobileWireFrameSketch',
        'mobileWireFrameImage',
        'mobileDesignImage',
      ].forEach((fieldName: string) => {
        if (
          typeof data[fieldName] === 'undefined' ||
          (typeof data[fieldName] !== 'undefined' && data[fieldName] === null)
        ) {
          data[fieldName] = '';
        }
      });
      setComponentData(data);
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong!');
      }
    }
  };

  const getComponentUsedPageList = async (id: string, page: number) => {
    try {
      const results = await componentApi.componetUsedPageList({
        componentId: id,
        page,
      });

      const pages = usedPages.concat(results.data.data);
      setUsedPages(pages);
      setUsedPagesTotalCount(results.data.count);
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong!');
      }
    }
  };

  const getRelatedComponentList = async (id: string, page: number) => {
    try {
      const results = await componentApi.relatedComponetList({
        componentId: id,
        page,
      });
      const components = relatedComponets.concat(results.data.componentData);
      setRelatedComponets(components);
      setRelatedTotalCount(results.data.count);
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong!');
      }
    }
  };

  const viewMorePages = () => {
    setCurrentUsedPage(currentUsedPage + 1);
    getComponentUsedPageList(componentId, currentUsedPage + 1);
  };

  const viewMoreRelatedComponents = () => {
    setCurrentRelatedPage(currentRelatedPage + 1);
    getRelatedComponentList(componentId, currentRelatedPage + 1);
  };

  const closeModal = () => {
    setComponentData(null);
    setUsedPages([]);
    setRelatedComponets([]);
    setUsedPagesTotalCount(1);
    setCurrentUsedPage(1);
    setRelatedTotalCount(1);
    setCurrentRelatedPage(1);
    detailsToggle();
  };

  if (usedPages && usedPagesTotalCount > 0) {
    pagesList = usedPages.map((page: any) => {
      const {
        // eslint-disable-next-line no-shadow
        prototype,
        // eslint-disable-next-line no-shadow
        prototypePage,
        // eslint-disable-next-line @typescript-eslint/camelcase
        prototype_page_id,
      } = page;
      return (
        <div
          className="ar-common-post"
          onClick={() =>
            selectPage({
              // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
              prototypeId: prototype && prototype._id,
              // eslint-disable-next-line @typescript-eslint/camelcase
              pageId: prototype_page_id,
            })
          }
        >
          <div className="image-container">
            <img
              src={
                prototype && prototype.image
                  ? `${s3BucketUrl}/${formatImageUrl(prototype.image)}`
                  : defaultComponentCover
              }
              alt="resource"
            />
          </div>
          <div className="ar-common-post__container">
            <div className="ar-common-post__title">
              {prototype && prototype.name}
            </div>
            <div className="ar-common-post__cat">
              Page : <span>{prototypePage && prototypePage.name}</span>
            </div>
          </div>
        </div>
      );
    });
  }

  if (relatedComponets && relatedTotalCount > 0) {
    relatedList = relatedComponets.map((component: any) => {
      const {
        _id: id,
        // eslint-disable-next-line no-shadow, @typescript-eslint/camelcase
        wire_frame_image,
        // eslint-disable-next-line no-shadow
        componentTypes,
        // eslint-disable-next-line no-shadow
        project,
        // eslint-disable-next-line no-shadow
        page,
      } = component;
      return (
        <div
          className="ar-common-post"
          onClick={() => window.open(`/components/${id}`, '_blank')}
        >
          <div className="image-container">
            <img
              src={
                // eslint-disable-next-line @typescript-eslint/camelcase
                wire_frame_image
                  ? `${s3BucketUrl}/${formatImageUrl(wire_frame_image)}`
                  : defaultComponentCover
              }
              alt="resource"
            />
          </div>
          <div className="ar-common-post__container">
            <div className="ar-common-post__title">{project}</div>
            <div className="ar-common-post__cat">
              Page : <span>{page}</span>
            </div>
            <div className="ar-common-post__type">
              Component Type(s):{' '}
              <span>
                {componentTypes &&
                  componentTypes
                    .map((x: { name: string }) => x.name)
                    .join(', ')}
              </span>
            </div>
          </div>
        </div>
      );
    });
  }

  useEffect(() => {
    if (detailsModal && componentId) {
      getComponent(componentId);
      getComponentUsedPageList(componentId, currentUsedPage);
      getRelatedComponentList(componentId, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsModal, componentId]);

  return (
    <>
      <Modal
        className="ar-edit-page__moodboard-popup ar-components-details-popup"
        isOpen={detailsModal}
        size="xl"
      >
        <ModalHeader className="ar-edit-page__moodboard-popup-title">
          <div className="container-fluid">
            <Row>
              <Col>Section details</Col>
            </Row>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="ar-components-details-popup-main-details">
            <div className="ar-components-details-popup-main-details-item-type">
              <p>Section type -&nbsp;</p>
              <span>
                {componentData?.allComponentTypes &&
                  componentData.allComponentTypes
                    .map((x: { name: string }) => x.name)
                    .join(', ')}
              </span>
            </div>
            <div className="ar-components-details-popup-main-details-item-project">
              <p>Project -&nbsp;</p>
              <span>{componentData?.project?.label}</span>
            </div>
          </div>
          <div className="ar-components-details-popup-wrapper">
            <div className="ar-components-details-popup-slider ar-components-details-popup-slider-main">
              <div className="ar-components-details-popup-slider-actions">
                <Button
                  type="button"
                  className="ar-btn ar-btn--ternary sketch"
                  // eslint-disable-next-line no-return-assign
                  onClick={() =>
                    componentData?.wireFrameSketch
                      ? (window.location.href = `${s3BucketUrl}/${componentData?.wireFrameSketch}`)
                      : ''
                  }
                  disabled={!componentData?.wireFrameSketch}
                >
                  Sketch
                </Button>
                {/* TODO */}
                {/* <Button type="button" className="ar-btn ar-btn--ternary figma">
                  Figma
                </Button> */}
              </div>
              <Slider
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...settings}
              >
                {componentData?.wireFrameImage && (
                  <div>
                    <div>
                      <img
                        src={`${s3BucketUrl}/${formatImageUrl(
                          componentData?.wireFrameImage,
                        )}`}
                        alt="wireFrameImage"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                )}
                {componentData?.designImage && (
                  <div>
                    <div>
                      <img
                        src={`${s3BucketUrl}/${formatImageUrl(
                          componentData?.designImage,
                        )}`}
                        alt="designImage"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                )}
                {componentData?.mobileWireFrameImage && (
                  <div>
                    <div>
                      <img
                        src={`${s3BucketUrl}/${formatImageUrl(
                          componentData?.mobileWireFrameImage,
                        )}`}
                        alt="mobileWireFrameImage"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                )}
                {componentData?.mobileDesignImage && (
                  <div>
                    <div>
                      <img
                        src={`${s3BucketUrl}/${formatImageUrl(
                          componentData?.mobileDesignImage,
                        )}`}
                        alt="mobileDesignImage"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                )}
                {!componentData?.wireFrameImage &&
                  !componentData?.designImage &&
                  !componentData?.mobileWireFrameImage &&
                  !componentData?.mobileDesignImage && (
                    <div>
                      <div>
                        <img
                          src={defaultComponentCover}
                          alt="defaultImage"
                          className="img-fluid cover"
                        />
                      </div>
                    </div>
                  )}
              </Slider>
            </div>

            <div className="ar-components-details-popup-other-details">
              <div className="ar-components-details-popup-badges">
                {componentData?.isInRealm && (
                  <span className="badge badge-pill badge-success realm-badge">
                    Realm
                  </span>
                )}
                {componentData?.isByReference && (
                  <span className="badge badge-pill badge-success by-reference">
                    By Reference
                  </span>
                )}
                {componentData?.isByRevision && (
                  <span className="badge badge-pill badge-success by-reference">
                    By Revision
                  </span>
                )}
              </div>
              <div className="ar-components-details-popup-details">
                {componentData?.isInRealm && (
                  <div className="ar-components-details-popup-details-item">
                    <p>SKU ID</p>
                    <span>{componentData?.skuID}</span>
                  </div>
                )}
                <div className="ar-components-details-popup-details-item">
                  <p>Last update date</p>
                  <span>
                    {moment(componentData?.updatedAt).format('DD MMM YYYY')}
                  </span>
                </div>
                {componentData?.user && (
                  <div className="ar-components-details-popup-details-item">
                    <p>Last updated by</p>
                    <span>{componentData?.user}</span>
                  </div>
                )}
                {/* TODO */}
                {/* <div className="ar-components-details-popup-details-item">
                  <p>Version</p>
                  <span>1.0.3.0</span>
                </div> */}
              </div>
              <div className="ar-components-details-popup-usage-count">
                <p>{usedPagesTotalCount}</p>
                <span>Usage Count</span>
              </div>
            </div>
          </div>
          <div className="ar-components-details-popup-status-wrapper">
            <p className="ar-components-details-popup-sub-heading">Status</p>
            <div className="ar-components-details-popup-status">
              <span
                className={`ar-components-details-popup-status-item ${
                  componentData?.designImage ? 'available' : ''
                }`}
                onClick={() =>
                  componentData?.designImage
                    ? window.open(
                        `${s3BucketUrl}/${formatImageUrl(
                          componentData?.designImage,
                        )}`,
                      )
                    : ''
                }
              >
                <span>Design</span>
              </span>
              <span
                className={`ar-components-details-popup-status-item ${
                  componentData?.sectionFRD ? 'available' : ''
                }`}
                onClick={() =>
                  componentData?.sectionFRD
                    ? window.open(componentData?.sectionFRD)
                    : ''
                }
              >
                <span>FRD</span>
              </span>
              <span
                className={`ar-components-details-popup-status-item ${
                  componentData?.sectionTestCaseDoc ? 'available' : ''
                }`}
                onClick={() =>
                  componentData?.sectionTestCaseDoc
                    ? window.open(componentData?.sectionTestCaseDoc)
                    : ''
                }
              >
                <span>Test Cases</span>
              </span>
              <span
                className={`ar-components-details-popup-status-item ${
                  componentData?.sectionDevPlanDoc ? 'available' : ''
                }`}
                onClick={() =>
                  componentData?.sectionDevPlanDoc
                    ? window.open(componentData?.sectionDevPlanDoc)
                    : ''
                }
              >
                <span>Dev Plan</span>
              </span>
              <span
                className={`ar-components-details-popup-status-item ${
                  componentData?.sectionUserGuide ? 'available' : ''
                }`}
                onClick={() =>
                  componentData?.sectionUserGuide
                    ? window.open(componentData?.sectionUserGuide)
                    : ''
                }
              >
                <span>UG</span>
              </span>
            </div>
          </div>
          <div
            className={`ar-components-details-popup-currently-usedin ${
              usedPagesTotalCount > 3 &&
              usedPages.length !== usedPagesTotalCount
                ? ''
                : 'no-view-more'
            }`}
          >
            <p className="ar-components-details-popup-sub-heading">
              Currently used in{' '}
            </p>
            <div className="ar-components-details-popup-currently-usedin-usage-count">
              <p>
                Usage Count : <span>{usedPagesTotalCount}</span>
              </p>
            </div>
            <div className="ar-components-details-popup-currently-usedin-list">
              <div className="ar-components-details-popup-currently-usedin-list-inner">
                {pagesList && pagesList.length ? (
                  <>
                    {pagesList}
                    {usedPagesTotalCount > 3 &&
                      usedPages.length !== usedPagesTotalCount && (
                        <div className="ar-components-details-popup-view-more">
                          <span onClick={viewMorePages}>View more</span>
                        </div>
                      )}
                  </>
                ) : (
                  <div className="ar-components-details-popup-currently-usedin-list-empty">
                    <p>No currently used</p>
                    <span>
                      This section didn&apos;t used in any prototype yet.
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`ar-components-details-popup-currently-usedin ${
              relatedTotalCount > 3 &&
              relatedComponets.length !== relatedTotalCount
                ? ''
                : 'no-view-more'
            }`}
          >
            <p className="ar-components-details-popup-sub-heading">
              Related sections
            </p>
            <div className="ar-components-details-popup-currently-usedin-list">
              <div className="ar-components-details-popup-currently-usedin-list-inner">
                {relatedList && relatedList.length ? (
                  <>
                    {relatedList}
                    {relatedTotalCount > 3 &&
                      relatedComponets.length !== relatedTotalCount && (
                        <div className="ar-components-details-popup-view-more">
                          <span onClick={viewMoreRelatedComponents}>
                            View more
                          </span>
                        </div>
                      )}
                  </>
                ) : (
                  <div className="ar-components-details-popup-currently-usedin-list-empty">
                    <p>No related sections</p>
                    <span>
                      This section didn&apos;t have any related section.
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row className="w-100">
            <Col xs="12" className="d-flex justify-content-end">
              <Button
                type="button"
                className="ar-btn ar-btn--light btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DetailPopup;
