/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { getSanitizedS3BucketUrl } from '../../../helpers';
import defaultComponentCover from '../../../assets/images/default-component.png';
import prototypeApi from '../../../api/prototypeApi';

const PageDetailPopup = (props: any) => {
  const { pageModal, pageModalToggle, pageData } = props;

  let imageList: any = null;

  const s3BucketUrl = getSanitizedS3BucketUrl();

  const formatImageUrl = (url: string) =>
    url && url[0] === '/' ? url.substring(1, url.length) : url;

  const [pageComponents, setPageComponents] = useState([]);

  // eslint-disable-next-line no-shadow
  const getPrototypePageComponents = async (pageData: any) => {
    try {
      const results = await prototypeApi.getPageComponent(
        pageData.prototypeId,
        pageData.pageId,
      );
      setPageComponents(results.data.data);
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong!');
      }
    }
  };

  useEffect(() => {
    if (pageModal && pageData) {
      getPrototypePageComponents(pageData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageModal, pageData]);

  if (pageComponents && pageComponents.length) {
    imageList = pageComponents.map((component: any) => {
      const {
        // eslint-disable-next-line no-shadow
        wireFrameImage,
      } = component;

      return (
        <img
          src={
            wireFrameImage
              ? `${s3BucketUrl}/${formatImageUrl(wireFrameImage)}`
              : defaultComponentCover
          }
          alt="component"
          className="img-fluid"
        />
      );
    });
  }

  return (
    <>
      <Modal
        className="ar-components-details-popup ar-components-details-popup-item-modal"
        backdropClassName="item-modal-backdrop"
        isOpen={pageModal}
        size="lg"
      >
        <ModalHeader className="ar-edit-page__moodboard-popup-title">
          <div className="container-fluid">
            <Row>
              <Col>Prototype Page</Col>
            </Row>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="ar-components-details-popup-item-modal-view">
            {imageList}
          </div>
        </ModalBody>
        <ModalFooter>
          <Row className="w-100">
            <Col xs="12" className="d-flex justify-content-end">
              <Button
                type="button"
                className="ar-btn ar-btn--light btn btn-secondary"
                onClick={pageModalToggle}
              >
                Close
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PageDetailPopup;
