/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { Fragment, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ReactSortable } from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import {
  faArrowDown,
  faArrowUp,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';
import { get as getLsValue } from '../../../helpers/local-storage';
import defaultComponentCover from '../../../assets/images/default-component.png';
import { getSanitizedS3BucketUrl } from '../../../helpers';

export interface ItemType {
  id: string;
  _id: string;
  wireFrameImage: string;
  wire_frame_image: string;
  designImage: string;
  design_image: string;
  page?: string;
  project?: string;
  isInRealm: boolean;
  pageComponentId: string;
}

const PrototypePreview = (props: any) => {
  const {
    addedComponentList,
    setAddedComponentList,
    setIsFormUpdated,
    scrollIndex,
    setScrollIndex,
  } = props;

  const SweetAlert = withReactContent(Swal);
  const s3BucketUrl = getSanitizedS3BucketUrl();

  const handleDuplicate = (item: any) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      pageComponentId,
      ...itemWithoutPageComponentID
    } = item;
    const existingItems = [...addedComponentList];
    const index = existingItems.indexOf(item);
    existingItems.splice(index + 1, 0, itemWithoutPageComponentID);
    setAddedComponentList(existingItems);
    setIsFormUpdated(true);
    toast('The section has been duplicated', {
      type: 'success',
      autoClose: 5000,
    });
  };

  const handleRemove = (itemIndex: number) => {
    SweetAlert.fire({
      title: 'Confirm Action',
      text: 'Are you sure you want to remove this section from page?',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const itemsCopy = [...addedComponentList];
        itemsCopy.splice(itemIndex, 1);
        setAddedComponentList(itemsCopy);
        setIsFormUpdated(true);
      }
    });
  };

  const handleMoveUp = (item: any) => {
    const index = addedComponentList.indexOf(item);
    if (index > 0) {
      const existingItems = [...addedComponentList];
      existingItems.splice(index, 1);
      existingItems.splice(index - 1, 0, item);
      setAddedComponentList(existingItems);
      setIsFormUpdated(true);
      return true;
    }
    return false;
  };

  const handleMoveDown = (item: any) => {
    const index = addedComponentList.indexOf(item);
    if (index < addedComponentList.length - 1) {
      const existingItems = [...addedComponentList];
      existingItems.splice(index, 1);
      existingItems.splice(index + 1, 0, item);
      setAddedComponentList(existingItems);
      setIsFormUpdated(true);
      return true;
    }
    return false;
  };

  const presentationModeIsDesign = getLsValue(
    'theme-setting-show-designs',
    false,
  );

  useEffect(() => {
    const element = document.getElementById(`id-${scrollIndex}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    return () => {
      setScrollIndex(-1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollIndex]);

  const formatImageUrl = (url: string) =>
    url && url[0] === '/' ? url.substring(1, url.length) : url;
  return (
    <>
      <div className="ar-common-popup__prv">
        <ReactSortable
          animation={600}
          ghostClass="ar-sortable__drag-area"
          list={addedComponentList}
          setList={setAddedComponentList}
          className="w-100"
          onChange={() => setIsFormUpdated(true)}
        >
          {addedComponentList.map((item: any, itemIndex: number) => (
            <Fragment key={item.pageComponentId}>
              <div
                className="ar-edit-page__wrapper"
                key={item.pageComponentId}
                id={`id-${itemIndex}`}
              >
                <div className="ar-edit-page__badges">
                  {item.isInRealm ? (
                    <span className="badge badge-pill badge-success realm-badge">
                      Realm
                    </span>
                  ) : null}
                  {item.isRevised && (
                    <>
                      <span className="badge badge-pill badge-success">
                        Revision
                      </span>
                    </>
                  )}
                  {item.isReference && (
                    <>
                      <span className="badge badge-pill badge-success">
                        Reference
                      </span>
                    </>
                  )}
                </div>
                <div className="ar-edit-page__details">
                  <div className="ar-edit-page__project">
                    <span>{item.project}</span>
                    <span>{item.page}</span>
                  </div>
                  <div className="ar-edit-page__actions text-right">
                    <button
                      aria-label="Duplicate button"
                      id={`duplicate-button-${item.id}`}
                      type="button"
                      className="ar-btn ar-btn--secondary ar-btn--secondary--duplicate ar-btn--icon-rounded"
                      // eslint-disable-next-line no-underscore-dangle
                      onClick={() => handleDuplicate(item)}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`duplicate-button-${item.id}`}
                    >
                      Duplicate Component
                    </UncontrolledTooltip>

                    <button
                      id={`remove-button-${item.id}`}
                      type="button"
                      className="btn btn-danger rounded-circle"
                      // eslint-disable-next-line no-underscore-dangle
                      onClick={() => handleRemove(itemIndex)}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </button>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`remove-button-${item.id}`}
                    >
                      Remove Component
                    </UncontrolledTooltip>

                    <div className="ar-edit-page__actions__move ml-2">
                      <button
                        id={`move-up-button-${item.id}`}
                        type="button"
                        className="ar-btn ar-btn--primary rounded-circle"
                        // eslint-disable-next-line no-underscore-dangle
                        onClick={() => handleMoveUp(item)}
                        disabled={addedComponentList.indexOf(item) === 0}
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </button>
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`move-up-button-${item.id}`}
                      >
                        Move Up
                      </UncontrolledTooltip>

                      <button
                        id={`move-down-button-${item.id}`}
                        type="button"
                        className="ar-btn ar-btn--primary rounded-circle"
                        // eslint-disable-next-line no-underscore-dangle
                        onClick={() => handleMoveDown(item)}
                        disabled={
                          addedComponentList.indexOf(item) ===
                          addedComponentList.length - 1
                        }
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </button>
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`move-down-button-${item.id}`}
                      >
                        Move Down
                      </UncontrolledTooltip>

                      <button
                        id={`drag-screen-button-${item.id}`}
                        type="button"
                        className="ar-btn ar-btn--light ar-btn--light-drag rounded-circle"
                        aria-label="Drag"
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`drag-screen-button-${item.id}`}
                      >
                        Drag and drop
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
                {(presentationModeIsDesign &&
                  (item.designImage || item.design_image)) ||
                (!presentationModeIsDesign &&
                  (item.wireFrameImage || item.wire_frame_image)) ? (
                  <img
                    src={`${s3BucketUrl}/${
                      presentationModeIsDesign
                        ? formatImageUrl(item.designImage) ||
                          formatImageUrl(item.design_image)
                        : formatImageUrl(item.wireFrameImage) ||
                          formatImageUrl(item.wire_frame_image)
                    }`}
                    alt="default component"
                    className="cover"
                  />
                ) : (
                  <img
                    src={defaultComponentCover}
                    alt="default component"
                    className="cover"
                  />
                )}
              </div>
            </Fragment>
          ))}
        </ReactSortable>
      </div>
    </>
  );
};

export default PrototypePreview;
