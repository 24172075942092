import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { throttle, DebouncedFunc } from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import prototypeApi from '../../../api/prototypeApi';
import Pagination from '../../../components/common/Pagination';
import PrototypeGridItem from '../components/prototypeGridItem';
import useWindowSize from '../../../helpers/useWindowSizeHook';
import pageApi from '../../../api/pageApi';
import PageLoading from '../../../components/common/pageLoad';
/* eslint-disable @typescript-eslint/camelcase */
import {
  PAGE_LIMIT,
  PAGE_LIMIT_4K,
  BREAK_POINT_4k,
  MOODBOARD_EMAIL_HEADING,
  MOODBOARD_EMAIL_MESSAGE,
} from '../../../constants';
/* eslint-enable */
import notifyIcon from '../../../assets/images/Info_Icon.svg';

const SweetAlert = withReactContent(Swal);
const MoodboardConfig = (props: any) => {
  const { history } = props;
  let pageList: any = null;
  let totalCount = 1;

  const isMounted = useRef(false);
  const getPagesThrottled = useRef<DebouncedFunc<any> | null>(null);
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const [data, setData] = useState({ data: [{}], count: 1 });
  const { deviceWidth } = useWindowSize();
  const [params, setParams] = useState<{
    search: string | null;
    page: number | 0;
    limit: number | 0;
    prototypes: string[] | null;
  }>({
    search: '',
    page: 1,
    // eslint-disable-next-line @typescript-eslint/camelcase
    limit: deviceWidth > BREAK_POINT_4k ? PAGE_LIMIT_4K : PAGE_LIMIT,
    prototypes: [],
  });
  const [pageMoodboardIsGenerating, setPageMoodboardIsGenerating] = useState<
    string | null
  >(null);

  const [prototypeLists, setPrototypeLists] = useState<
    {
      _id: string;
      name: string;
    }[]
  >([]);

  const getPrototypes = (args: any) => {
    if (deviceWidth > 0) {
      if (getPagesThrottled.current) getPagesThrottled.current.cancel();
      getPagesThrottled.current = throttle(
        async (filterValues: any) => {
          try {
            const { search, page, limit, prototypes } = filterValues;
            const paramData: any = {
              search,
              page,
              limit,
              prototypes,
            };
            setRequestDataComplete(false);
            const results = await pageApi.searchPages(paramData);
            setData(results.data);
            setRequestDataComplete(true);
          } catch (e) {
            if (e.response && e.response.status) {
              setRequestDataComplete(false);
            }
          }
        },
        600,
        { leading: false, trailing: true },
      );
      getPagesThrottled.current(args);
    }
  };

  const getPrototypeList = async () => {
    try {
      const results = await prototypeApi.requestAllPrototypesList();
      setPrototypeLists(results.data.prototypeData);
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('prototypes get error', e.response);
      }
    }
  };

  useEffect(() => {
    getPrototypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPrototypes(params);
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    params.page = 1;
    getPrototypes(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.search]);

  useEffect(() => {
    if (deviceWidth > 0) {
      setParams({
        ...params,
        // eslint-disable-next-line @typescript-eslint/camelcase
        limit: deviceWidth > BREAK_POINT_4k ? PAGE_LIMIT_4K : PAGE_LIMIT,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceWidth]);

  const handleEditClick = (page: any) => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    if (page.page_component_count === 0) {
      toast('This page is empty. Please add sections to build the Moodboard', {
        type: 'error',
        autoClose: 4000,
      });
    } else {
      history.push({
        // eslint-disable-next-line no-underscore-dangle
        pathname: `/moodgen/edit-page/${page.prototype_id}/${page._id}`,
        state: {
          prototypeName: page.prototype,
          pageName: page.name,
        },
      });
    }
  };

  const handleMoodboardGenerate = async (
    prototypeId: string,
    pageId: string,
    // eslint-disable-next-line @typescript-eslint/camelcase
    page_component_count: number,
  ) => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    if (page_component_count === 0) {
      toast('This page is empty. Please add sections to build the Moodboard', {
        type: 'error',
        autoClose: 4000,
      });
    } else {
      setPageMoodboardIsGenerating(pageId);
      try {
        await prototypeApi.generatePageMoodboard(prototypeId, pageId);
        setPageMoodboardIsGenerating(null);
        SweetAlert.fire({
          title: MOODBOARD_EMAIL_HEADING,
          text: MOODBOARD_EMAIL_MESSAGE,
          icon: 'info',
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        toast(error.response.data.message, {
          type: 'error',
          autoClose: 5000,
        });
        setPageMoodboardIsGenerating(null);
      }
    }
  };

  if (requestDataComplete && data.data.length > 0) {
    totalCount = data.count;
    pageList = data.data.map((item: any) => {
      return (
        <PrototypeGridItem
          itemKey={item._id} // eslint-disable-line no-underscore-dangle
          item={item}
          onPageEdit={() => handleEditClick(item)}
          onGenerateMoodgen={() =>
            handleMoodboardGenerate(
              item.prototype_id,
              item._id, // eslint-disable-line no-underscore-dangle
              item.page_component_count,
            )
          }
        />
      );
    });
  } else if (
    ((params.search && params.search.length > 0) ||
      (params.prototypes && params.prototypes.length > 0)) &&
    requestDataComplete &&
    data.data.length === 0
  )
    pageList = (
      <span className="mx-auto py-3">
        Sorry, Your filter produced no results!
      </span>
    );
  else if (requestDataComplete && data.data.length === 0) {
    pageList = (
      <span className="mx-auto py-3">
        There is no moodboard ready prototype pages to display
      </span>
    );
  }

  const pageCount = totalCount / params.limit;

  const onPageChange = useCallback(
    ({ selected }) => {
      const newParams = { ...params, page: selected + 1 };

      setParams(newParams);
      getPrototypes(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  const onPageSelect = useCallback(
    ({ target }) => {
      const newParams = { ...params, page: 1, limit: target.value };
      setParams(newParams);
      getPrototypes(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  const prototypeOptions: { value: string | null; label: string }[] = [
    ...prototypeLists.map((prototype) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: prototype._id,
      label: prototype.name,
    })),
  ];

  return (
    <div className="ar-common-content">
      <div className="ar-common-search-panel-wrapper">
        {pageMoodboardIsGenerating ? (
          <PageLoading loadingText="Generating moodboard. Please wait..." />
        ) : null}
        <Row>
          <Col>
            <div className="form ar-common-form ar-common-search-panel ar-common-search-panel--moodboard-list">
              <div className="ar-common-form__group">
                <FormGroup className="ar-common-form-search">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <Input
                    type="text"
                    className="search ar-common-form__input"
                    id="search"
                    placeholder="Search by page name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      setParams({ ...params, page: 1, search: e.target.value })
                    }
                  />
                </FormGroup>
              </div>
              <div className="ar-common-form__group">
                <FormGroup>
                  <Label>Prototypes</Label>
                  <Select
                    isMulti
                    className="ar-common-form__select"
                    classNamePrefix="rctslct"
                    id="project"
                    options={prototypeOptions}
                    onChange={(selectedPrototype) => {
                      const selectedOptions: any = [];
                      // eslint-disable-next-line no-unused-expressions
                      selectedPrototype?.forEach((item) => {
                        selectedOptions.push(item.value);
                      });
                      setParams({
                        ...params,
                        page: 1,
                        prototypes: selectedOptions || null,
                      });
                    }}
                  />
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <div className="ar-common-content-alert-msg">
              <div className="alert alert-success" role="alert">
                <div className="ar-common-content-alert-msg-icon">
                  <img src={notifyIcon} alt="Notify" className="img-fluid" />
                </div>
                <div>
                  Please note that the prototype pages with the status
                  &quot;Moodgen Ready &quot; are listed here. The status of each
                  prototype page can be changed under{' '}
                  <a href="/prototypes">&apos;Prototypes&apos;</a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          {requestDataComplete === false ? (
            <div style={{ minHeight: 250 }}>
              <div className="loading-indicator" />
            </div>
          ) : (
            <div className="ar-common-content__inner">
              <div className="ar-common-posts">
                <div
                  className={`ar-common-posts__list ${
                    data.data.length === 0 ? 'd-block' : ''
                  }`}
                >
                  {pageList}
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row xs="1" sm="1" md="1" className="title-wrapper">
        <Col className="main-title">
          {requestDataComplete && deviceWidth > 0 && (
            <Pagination
              count={totalCount}
              pageCount={pageCount}
              onPageChange={onPageChange}
              onPageSelect={onPageSelect}
              pageLimit={params.limit}
              currentPage={params.page}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default MoodboardConfig;
