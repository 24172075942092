/* eslint-disable no-underscore-dangle */

import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
} from 'react';
import {
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Button,
  Label,
} from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ReactSortable } from 'react-sortablejs';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faArrowUp,
  faArrowDown,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Formik, ErrorMessage, FormikProps } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import moment from 'moment';
import defaultComponentCover from '../../../assets/images/default-component.png';
import prototypeApi from '../../../api/prototypeApi';
import { get as getLsValue } from '../../../helpers/local-storage';
import { getSanitizedS3BucketUrl } from '../../../helpers';
import MoodboardPopup from '../components/moodboardPopup';
import FloatBtnGroup from '../../../components/common/FloatBtnGroup';
import FloatBtn from '../../../components/common/FloatBtn';
import AddSectionsPopup from '../components/addSectionsPopup';
import PrototypePreview from '../components/prototypePreview';
import Alert from '../../../components/common/Alert';
import DragAndDropField from '../../../components/FormFields/DragAndDropField';
import componentApi from '../../../api/componentApi';
import { MOODGEN_READY, APPROVED } from '../../../constants';

const SweetAlert = withReactContent(Swal);
export interface ItemType {
  id: string;
  _id: string;
  wireFrameImage: string;
  wireFrameSketch: string;
  wire_frame_image: string;
  designImage: string;
  design_image: string;
  page?: string;
  project?: string;
  isInRealm: boolean;
  pageComponentId: string;
  types: any;
  page_id: string;
  isRevised: boolean;
  isReference: boolean;
  order: number;
  reference_id: string;
  hidden: boolean;
  comments: any;
}

export interface LabelPosition {
  _id: string;
  left: string;
  top: string;
  display: string;
  comment: string;
  itemId: string;
  user: any;
  // eslint-disable-next-line no-underscore-dangle
  created_at: any;
}

export interface CursorPosition {
  x: number;
  y: number;
  opacity: number;
  zIndex: any;
}

const EditPage = (props: {
  match: {
    params: { prototypeId: string; pageId: string };
  };
  history: any;
  location: {
    prototypeName: any;
    pageName: any;
    state: {
      prototypeName: string;
      pageName: string;
      checkedValue: any;
      status: number;
    };
  };
}) => {
  const {
    match: {
      params: { prototypeId, pageId: prototypePageId },
    },
    location,
    history,
  } = props;

  // const formRef = useRef<FormikProps>();
  const formRef = useRef<FormikProps<any>>(null);

  const [items, setItems] = useState<ItemType[]>([]);
  const [addedComponentList, setAddedComponentList] = useState<ItemType[]>([]);
  const [prototypeName, setPrototypeName] = useState<string>('');
  const [pageName, setPageName] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [emptyPage, setEmptyPage] = useState<boolean>(false);
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [isLandingPageUpdated, setIsLandingPageUpdated] =
    useState<boolean>(false);
  const [scrollIndex, setScrollIndex] = useState<number>(-1);
  const [addSectionIndex, setAddSectionIndex] = useState<number>(-1);

  const [selectedRevisionItem, setSelectedRevisionItem] = useState<ItemType>();
  const [revisionItem, setRevisionItem] = useState<any>();

  const [initialValues, setInitialValues] = useState<{
    wireFrameSketch: string;
    wireFrameImage: string;
  }>({
    wireFrameSketch: '',
    wireFrameImage: '',
  });
  const [isFileUploading, setIsFileUploading] = useState<{
    wireFrameSketch: boolean;
    wireframeImage: boolean;
  }>({
    wireFrameSketch: false,
    wireframeImage: false,
  });
  const [tempComponentId, setTempComponentId] = useState<string>('');
  const [prototypeStatus, setPrototypeStatus] = useState<number>(
    location.state.status,
  );
  const [isRevisionUpdated, setIsRevisionUpdated] = useState<boolean>(false);
  const [leaveAlertOpen, setLeaveAlertOpen] = useState<boolean>(false);

  const [tempComponentIdList, setTempComponentIdList] = useState<any>([]);

  const [tooltipPosition, setTooltipPosition] = useState<CursorPosition>({
    x: 0,
    y: 0,
    opacity: 0,
    zIndex: -1,
  });

  const handleMouseMove = (e: {
    clientX: any;
    clientY: any;
    currentTarget: any;
  }) => {
    const image = e.currentTarget;
    const imageRect = image.getBoundingClientRect();

    const X = e.clientX - imageRect.left;
    const Y = e.clientY - imageRect.top;
    setTooltipPosition({
      x: X,
      y: Y,
      opacity: 1,
      zIndex: 9999,
    });
  };

  const handleMouseOut = () => {
    setTooltipPosition({
      x: 0,
      y: 0,
      opacity: 0,
      zIndex: -1,
    });
  };

  const validationSchema = Yup.object({
    wireFrameImage: Yup.string().required('Wire Frame Image Required'),
  });

  const [isActionDisable, setIsActionDisable] = useState<{
    revisionAction: boolean;
    deleteAction: boolean;
    manageSectionAction: boolean;
  }>({
    revisionAction: false,
    deleteAction: false,
    manageSectionAction: false,
  });

  const toggle = (save?: boolean) => {
    setIsOpen(!isOpen);
    setIsFormUpdated(false);
    setAddedComponentList(items);
    setScrollIndex(-1);

    if (tempComponentIdList.length && !save) {
      tempComponentIdList.forEach((element: any) => {
        prototypeApi.removeComponent({
          // eslint-disable-next-line no-underscore-dangle
          componentId: element,
          allow: true,
        });
      });
    }
  };

  const [revisionModal, setRevisionModal] = React.useState(false);
  const [revisionViewModal, setRevisionViewModal] = React.useState(false);

  const revisionToggle = () => {
    setRevisionModal(!revisionModal);
  };

  const revisionViewToggle = () => {
    setRevisionViewModal(!revisionViewModal);
  };

  // Cemment section
  const [labels, setLabels] = useState<LabelPosition[]>([]);

  const [activePopupIndex, setActivePopupIndex] = useState<number | null>(null);
  const [activeCommentIndex, setActiveCommentIndex] = useState<number | null>(
    null,
  );

  const [showComment, setShowComment] = useState<boolean>(false);
  const [isNewComment, setIsNewComment] = useState<boolean>(true);
  const [isUpdateComment, setIsUpdateComment] = useState<boolean>(false);

  const [comment, setComment] = useState<string>('');

  const [popupPosition, setPopupPosition] = useState<LabelPosition>({
    _id: '',
    left: '0',
    top: '0',
    display: 'none',
    comment: '',
    itemId: '',
    user: null,
    // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
    created_at: '',
  });

  const getPageComponent = async (
    prototype: string,
    pageToGet: string,
    itemId?: string,
  ) => {
    try {
      const results = await prototypeApi.getPageComponent(prototype, pageToGet);

      if (results.data.data && results.data.data.length > 0) {
        const newPageComponent = results.data.data.map((item: any) => {
          /* eslint-disable @typescript-eslint/camelcase */
          const {
            component_id: componentId,
            wireFrameImage,
            designImage,
            page,
            project,
            is_in_realm: isInRealm,
            page_component_id: pageComponentId,
            project_id: projectId,
            wireFrameSketch,
            types,
            page_id,
            isRevised,
            isReference,
            order,
            reference_id,
            comments,
          } = item;

          // eslint-disable-next-line no-unused-expressions
          pageComponentId === itemId && setLabels(comments);

          return {
            _id: componentId,
            id: componentId,
            wireFrameImage,
            designImage,
            page,
            project,
            isInRealm,
            pageComponentId,
            projectId,
            wireFrameSketch,
            types,
            page_id,
            isRevised: isRevised || false,
            isReference: isReference || false,
            order,
            reference_id: reference_id || null,
            comments,
            hidden: pageComponentId === itemId,
          };
        });

        /* eslint-enable */
        setItems(newPageComponent);
        setAddedComponentList(newPageComponent);
      }
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('component get error', e.response);
      }
    } finally {
      setEmptyPage(true);
    }
  };

  useEffect(() => {
    if (location && location.state) {
      if (Array.isArray(location.state.checkedValue)) {
        setItems(location.state.checkedValue);
      } else {
        getPageComponent(prototypeId, prototypePageId);
      }
      if (location.state.prototypeName) {
        setPrototypeName(location.state.prototypeName);
      }
      if (location && location.state.pageName) {
        setPageName(location.state.pageName);
      }
    }
    if (location && location.prototypeName) {
      setPrototypeName(location.prototypeName);
    }
    if (location && location.pageName) {
      setPageName(location.pageName);
    }
    if (prototypeStatus === MOODGEN_READY) {
      toast(
        'User will not be able to Add/edit new components or add revisions under the "Moodgen Ready status"',
        {
          type: 'warning',
          autoClose: 20000,
        },
      );
    }
    if (prototypeStatus === APPROVED) {
      toast(
        'User will not be able to Add/edit/delete new components under the "Approved status"',
        {
          type: 'warning',
          autoClose: 20000,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prototypeStatus === MOODGEN_READY) {
      setIsActionDisable({
        revisionAction: true,
        deleteAction: true,
        manageSectionAction: true,
      });
    } else if (prototypeStatus === APPROVED) {
      setIsActionDisable({
        revisionAction: false,
        deleteAction: true,
        manageSectionAction: true,
      });
    } else {
      setIsActionDisable({
        revisionAction: false,
        deleteAction: false,
        manageSectionAction: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prototypeStatus]);

  const handleCancel = () => {
    history.push({
      pathname: `/prototype/create-page/${prototypeId}/${prototypeName}`,
    });
  };

  const saveFunc = async (savedItems: any) => {
    const components = savedItems.map(
      (item: {
        id: string;
        _id: string;
        pageComponentId: string;
        is_by_reference: boolean;
        reference_id: string;
      }) => {
        // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
        return {
          // eslint-disable-next-line no-underscore-dangle
          componentId: item.id || item._id,
          pageComponentId: item.pageComponentId,
          status: 1,
          isReference: item.is_by_reference,
          // eslint-disable-next-line @typescript-eslint/camelcase
          reference_id: item.reference_id,
        };
      },
    );

    const saveResponse = await prototypeApi.savePageComponent({
      /* eslint-disable @typescript-eslint/camelcase */
      page_id: prototypePageId,
      prototype_id: prototypeId,
      components,
      /* eslint-enable */
    });
    return saveResponse;
  };

  const handleSave = async () => {
    const saveResponse = await saveFunc(items);
    setIsLandingPageUpdated(false);
    setTempComponentIdList([]);

    toast('Your changes have been saved successfully', {
      type: 'success',
      autoClose: 5000,
    });
    if (saveResponse.data.status) {
      history.push({
        pathname: `/prototype/create-page/${prototypeId}/${prototypeName}`,
      });
    }
  };

  const handleRemove = (itemIndex: number) => {
    SweetAlert.fire({
      title: 'Confirm Action',
      text: 'Are you sure you want to remove this section from page?',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const itemsCopy = [...items];
        itemsCopy.splice(itemIndex, 1);
        setItems(itemsCopy);
        setIsLandingPageUpdated(true);
      }
    });
  };

  const handleDuplicate = (item: any) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      pageComponentId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isRevised,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ...itemWithoutPageComponentID
    } = item;
    const existingItems = [...items];
    const index = existingItems.indexOf(item);
    existingItems.splice(index + 1, 0, itemWithoutPageComponentID);
    setItems(existingItems);
    setIsLandingPageUpdated(true);
    toast('The section has been duplicated', {
      type: 'success',
      autoClose: 5000,
    });
  };

  const handleMoveUp = (item: any) => {
    const index = items.indexOf(item);
    if (index > 0) {
      const existingItems = [...items];
      existingItems.splice(index, 1);
      existingItems.splice(index - 1, 0, item);
      setItems(existingItems);
      setIsLandingPageUpdated(true);
      return true;
    }
    return false;
  };

  const handleMoveDown = (item: any) => {
    const index = items.indexOf(item);
    if (index < items.length - 1) {
      const existingItems = [...items];
      existingItems.splice(index, 1);
      existingItems.splice(index + 1, 0, item);
      setItems(existingItems);
      setIsLandingPageUpdated(true);
      return true;
    }
    return false;
  };

  const s3BucketUrl = getSanitizedS3BucketUrl();
  const formatImageUrl = (url: string) =>
    url && url[0] === '/' ? url.substring(1, url.length) : url;

  const presentationModeIsDesign = getLsValue(
    'theme-setting-show-designs',
    false,
  );

  const saveData = async () => {
    setItems(addedComponentList);
    setIsFormUpdated(false);
    await saveFunc(addedComponentList);
    getPageComponent(prototypeId, prototypePageId);

    toast('Your changes have been saved successfully', {
      type: 'success',
      autoClose: 5000,
    });
  };

  const onItemSelect = useCallback(
    async ({ selectedComponent, event }) => {
      if (event.target.checked) {
        if (selectedComponent.isReference) {
          const existingItems = [...addedComponentList];

          const submitData: any = {
            project: selectedComponent.project,
            page: selectedComponent.page,
            componentTypes: [],
            order:
              addSectionIndex === -1
                ? existingItems.length + 1
                : addSectionIndex + 2,
            isByRevision: false,
            isByReference: true,
            image: selectedComponent.designImage,
          };
          let saveResponse;
          try {
            saveResponse = await componentApi.saveRevisionOrReferenceComponent(
              submitData,
            );
          } catch (error) {
            return;
          }
          if (saveResponse) {
            const savedComponent = saveResponse.data.data;
            // eslint-disable-next-line @typescript-eslint/camelcase
            savedComponent.reference_id = selectedComponent.reference_id;
            savedComponent.project = selectedComponent.project;
            savedComponent.page = selectedComponent.page;

            if (addSectionIndex === -1) {
              setScrollIndex(existingItems.length - 1);
              existingItems.splice(existingItems.length + 1, 0, savedComponent);
            } else {
              setScrollIndex(addSectionIndex);
              existingItems.splice(addSectionIndex + 1, 0, savedComponent);
            }
            setAddedComponentList(existingItems);

            const existingTempIds = tempComponentIdList;
            // eslint-disable-next-line no-underscore-dangle
            existingTempIds.push(savedComponent._id);

            setTempComponentIdList(existingTempIds);
          }
        } else {
          const existingItems = [...addedComponentList];

          if (addSectionIndex === -1) {
            setScrollIndex(existingItems.length - 1);
            existingItems.splice(
              existingItems.length + 1,
              0,
              selectedComponent,
            );
          } else {
            setScrollIndex(addSectionIndex);
            existingItems.splice(addSectionIndex + 1, 0, selectedComponent);
          }
          setAddedComponentList(existingItems);
        }
      } else if (selectedComponent.isReference) {
        const currentIndex = addedComponentList.findIndex(
          // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
          (x) => x.reference_id === selectedComponent.reference_id,
        );

        const newList = addedComponentList.filter(
          (listItem: { reference_id: any }) => {
            return (
              // eslint-disable-next-line no-underscore-dangle
              listItem.reference_id !== selectedComponent.reference_id
            );
          },
        );
        setAddedComponentList(newList);
        setScrollIndex(currentIndex - 2);

        const currentItem = addedComponentList.find(
          // eslint-disable-next-line no-underscore-dangle
          (x) => x.reference_id === selectedComponent.reference_id,
        );
        // eslint-disable-next-line no-unused-expressions
        currentItem &&
          (await prototypeApi.removeComponent({
            // eslint-disable-next-line no-underscore-dangle
            componentId: currentItem._id,
            allow: true,
          }));
      } else {
        const currentIndex = addedComponentList.findIndex(
          // eslint-disable-next-line no-underscore-dangle
          (x) => x.id === selectedComponent._id,
        );

        const newList = addedComponentList.filter(
          (listItem: { id: any; _id: any }) => {
            return (
              // eslint-disable-next-line no-underscore-dangle
              listItem.id !== selectedComponent._id &&
              // eslint-disable-next-line no-underscore-dangle
              listItem._id !== selectedComponent._id
            );
          },
        );
        setAddedComponentList(newList);
        setScrollIndex(currentIndex - 2);
      }
      setIsFormUpdated(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAddedComponentList, addedComponentList],
  );

  // Comment section
  const toggleContentVisibility = (itemIndex: any) => {
    setActivePopupIndex(itemIndex);
    setShowComment(true);
    setIsNewComment(true);
    setItems((prevItems) =>
      prevItems.map((item, index) => {
        if (index === itemIndex) {
          setLabels(item.comments.length ? item.comments : []);
          return { ...item, hidden: !item.hidden };
        }
        return item;
      }),
    );
  };

  const exitComment = (itemIndex: any) => {
    setShowComment(false);
    setItems((prevItems) =>
      prevItems.map((item, index) => {
        if (index === itemIndex) {
          return { ...item, hidden: false };
        }
        return item;
      }),
    );
    setLabels([]);
    setPopupPosition({ ...popupPosition, display: 'none' });
    getPageComponent(prototypeId, prototypePageId);
  };

  const showPopup = (evt: any, index: number) => {
    const image = evt.currentTarget;
    const imageRect = image.getBoundingClientRect();

    const offsetX = evt.clientX - imageRect.left;
    const offsetY = evt.clientY - imageRect.top;
    setActivePopupIndex(index);
    setActiveCommentIndex(null);
    setPopupPosition({
      _id: '',
      left: `${offsetX}px`,
      top: `${offsetY}px`,
      display: 'block',
      comment: '',
      itemId: '',
      user: null,
      // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
      created_at: '',
    });
    setIsNewComment(true);
  };

  const addComment = async (itemId: string) => {
    let response;
    try {
      response = await prototypeApi.prototypePageAddComment({
        pageComponentId: itemId,
        comment,
        left: popupPosition.left,
        top: popupPosition.top,
      });
    } catch (error) {
      return;
    }
    if (response) {
      toast('Comment added successfully', {
        type: 'success',
        autoClose: 5000,
      });
      await getPageComponent(prototypeId, prototypePageId, itemId);
      setPopupPosition({
        ...popupPosition,
        display: 'none',
        comment,
        itemId,
      }); // Hide the popup after submitting
      setComment('');
      setIsNewComment(false);
    }
  };

  const deleteComment = async () => {
    let response;
    try {
      response = await prototypeApi.prototypePageDeleteComment(
        // eslint-disable-next-line no-underscore-dangle
        popupPosition._id,
      );
    } catch (error) {
      return;
    }
    if (response) {
      toast('Comment deleted successfully', {
        type: 'success',
        autoClose: 5000,
      });
      // await getPageComponent(prototypeId, prototypePageId, itemId);

      const itemsCopy = [...labels];
      const currentIndex = labels.findIndex(
        (x) => x.comment === popupPosition.comment,
      );
      itemsCopy.splice(currentIndex, 1);
      setLabels(itemsCopy);
      setPopupPosition({ ...popupPosition, display: 'none' });
    }
  };

  const editComment = async (commentId: string, itemId: string) => {
    let response;
    try {
      response = await prototypeApi.prototypePageUpdateComment({
        commentId,
        comment,
      });
    } catch (error) {
      return;
    }
    if (response) {
      toast('Comment edited successfully', {
        type: 'success',
        autoClose: 5000,
      });
      await getPageComponent(prototypeId, prototypePageId, itemId);

      setPopupPosition({
        ...popupPosition,
        display: 'none',
        comment,
        itemId,
      }); // Hide the popup after submitting
      setComment('');
      setIsUpdateComment(false);
    }
  };

  const hidePopup = () => {
    setIsNewComment(false);
    setIsUpdateComment(false);
    setPopupPosition({ ...popupPosition, display: 'none' }); // Hide the popup after submitting
  };

  const showLabelPopup = (label: LabelPosition, index: number) => {
    setActiveCommentIndex(index);
    setIsNewComment(false);
    setPopupPosition({
      ...label,
      top: `${parseInt(label.top, 10)}px`,
      display: popupPosition.display === 'block' ? 'none ' : 'block',
    });
  };

  const getRevisionDetails = async (id: string) => {
    let response;
    try {
      response = await prototypeApi.getRevisionDetails(id);
    } catch (error) {
      return;
    }

    if (response) {
      setRevisionItem(response.data.data);
      revisionViewToggle();
    }
  };

  const createTempComponent = async (selectedComponent: any, order: number) => {
    if (selectedComponent.isRevised) {
      getRevisionDetails(selectedComponent.pageComponentId);
    } else {
      const submitData: any = {
        project: location.state.prototypeName,
        page: pageName,
        componentTypes: selectedComponent.types,
        order,
        isByRevision: true,
        isByReference: false,
        image: '',
      };
      let saveResponse;
      try {
        saveResponse = await componentApi.saveRevisionOrReferenceComponent(
          submitData,
        );
      } catch (error) {
        return;
      }
      if (saveResponse) {
        const savedComponent = saveResponse.data.data;
        // eslint-disable-next-line no-underscore-dangle
        setTempComponentId(savedComponent._id);
        revisionToggle();
        setSelectedRevisionItem(selectedComponent);
      }
    }
  };

  const removeTempComponent = async () => {
    await prototypeApi.removeComponent({
      componentId: tempComponentId,
      allow: true,
    });
    revisionToggle();
    setTempComponentId('');
  };

  const handleUploadFile = useCallback(
    async (file, name, values) => {
      const { wireFrameSketch, wireFrameImage } = values;

      if (!file) return;
      setIsFormUpdated(true);
      setIsRevisionUpdated(true);

      setIsFileUploading({
        ...isFileUploading,
        [name]: true,
      });

      let fileType = '';
      const form = new FormData();
      form.append('image', file);

      switch (name) {
        case 'wireFrameSketch':
          fileType = 'sketch';
          break;
        case 'wireframeImage':
          fileType = 'wireframe';
          break;
        default:
          fileType = 'wireframe';
          break;
      }
      const componentId = tempComponentId;
      const uploadName = await componentApi.upload(form, fileType, componentId);
      setIsFileUploading({
        ...isFileUploading,
        [name]: false,
      });
      const newProps = {
        ...values,
        wireFrameSketch:
          name === 'wireFrameSketch'
            ? uploadName.data.fileKey
            : wireFrameSketch,
        wireFrameImage:
          name === 'wireFrameImage' ? uploadName.data.fileKey : wireFrameImage,
      };

      setInitialValues({
        ...initialValues,
        ...newProps,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tempComponentId, initialValues, setInitialValues],
  );

  const handleRevisionSubmitRef = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleRevisionSubmit = async () => {
    let saveResponse;
    try {
      saveResponse = await prototypeApi.revisePageComponent(
        selectedRevisionItem?.pageComponentId,
        tempComponentId,
      );
    } catch (error) {
      return;
    }

    if (saveResponse) {
      toast('Revision Saved successfully', {
        type: 'success',
        autoClose: 5000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const deleteRevision = () => {
    SweetAlert.fire({
      title: 'Confirm Action',
      text: 'Are you sure you want to remove this revision section?',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response;
        try {
          response = await prototypeApi.deleteRevisionDetails(
            revisionItem.pageComponentId,
          );
        } catch (error) {
          return;
        }
        if (response) {
          toast('Prototype revision deleted successfully', {
            type: 'success',
            autoClose: 5000,
          });
          setTimeout(() => {
            revisionViewToggle();
            window.location.reload();
          }, 2000);
        }
      }
    });
  };

  const changePrototypeStatus = async (status: number) => {
    let response;
    try {
      response = await prototypeApi.prototypePageStatusChange(
        prototypePageId,
        status,
      );
    } catch (error) {
      return;
    }

    if (response) {
      setPrototypeStatus(status);
      history.replace({
        pathname: `/prototype/edit-page/${prototypeId}/${prototypePageId}`,
        state: {
          prototypeName: location.state.prototypeName,
          pageName: location.state.pageName,
          status,
        },
      });

      toast('Prototype page status changed successfully', {
        type: 'success',
        autoClose: 5000,
      });

      if (status === MOODGEN_READY) {
        toast(
          'User will not be able to Add/edit new components or add revisions under the "Moodgen Ready status"',
          {
            type: 'warning',
            autoClose: 20000,
          },
        );
      }
      if (status === APPROVED) {
        toast(
          'User will not be able to Add/edit/delete new components under the "Approved status"',
          {
            type: 'warning',
            autoClose: 20000,
          },
        );
      }
    }
  };

  const statusOptions = [
    {
      value: 1,
      label: (
        <div className="inner-dropdown">
          <div className="shape-wrapper">
            <span className="shape in-design" />
          </div>
          In Design
        </div>
      ),
    },
    {
      value: 2,
      label: (
        <div className="inner-dropdown">
          <div className="shape-wrapper">
            <span className="shape in-verification" />
          </div>
          In Verification
        </div>
      ),
    },
    {
      value: 3,
      label: (
        <div className="inner-dropdown">
          <div className="shape-wrapper">
            <span className="shape approved" />
          </div>
          Approved
        </div>
      ),
    },
    {
      value: 4,
      label: (
        <div className="inner-dropdown">
          <div className="shape-wrapper">
            <span className="shape moodgen-ready" />
          </div>
          Moodgen Ready
        </div>
      ),
    },
  ];

  return (
    <div className="ar-common-content">
      <div className="ar-edit-page">
        <Row xs="1" sm="1" md="1">
          <Col className="sticky-top">
            <div className="ar-common-breadcrumb">
              <Link
                to={{
                  pathname: '/prototypes',
                }}
              >
                <button type="button" className="ar-common-breadcrumb__link">
                  Prototype
                </button>
              </Link>

              <FontAwesomeIcon icon={faChevronRight} />
              <Link
                to={{
                  pathname: `/prototype/create-page/${prototypeId}/${location.state.prototypeName}`,
                }}
              >
                <button type="button" className="ar-common-breadcrumb__link">
                  Edit page ({location.state.prototypeName})
                </button>
              </Link>
              <FontAwesomeIcon icon={faChevronRight} />
              <span className="ar-common-breadcrumb__page">
                Page Builder {pageName}
              </span>
            </div>
          </Col>
          <Col>
            <div className="ar-common-title"> Page Builder : {pageName}</div>
            <div>
              <div className="ar-common-status-dropdown">
                <div className="ar-common-status-dropdown-wrapper header-top">
                  <Select
                    classNamePrefix="rctslct-status"
                    options={statusOptions}
                    isSearchable={false}
                    value={
                      statusOptions.filter(
                        (item) => item.value === prototypeStatus,
                      )[0]
                    }
                    onChange={(selectedOption) =>
                      changePrototypeStatus(
                        selectedOption ? selectedOption?.value : 1,
                      )
                    }
                    isDisabled={!!(items.length === 0 && emptyPage)}
                  />
                </div>
              </div>
              <FloatBtnGroup>
                <FloatBtn
                  variant="cancel"
                  onClickHandler={handleCancel}
                  title="Back"
                  type="button"
                />
                <FloatBtn
                  type="submit"
                  variant="update"
                  onClickHandler={handleSave}
                  title="Save"
                  disabled={!isLandingPageUpdated}
                />
                <FloatBtn
                  variant="create"
                  type="button"
                  onClickHandler={() => {
                    setAddSectionIndex(-1);
                    setTempComponentIdList([]);
                    toggle(true);
                  }}
                  title="Manage Section"
                  disabled={showComment || isActionDisable.manageSectionAction}
                />
              </FloatBtnGroup>
              {items.length > 0 && (
                <div className="ar-edit-page__body">
                  <ReactSortable
                    animation={600}
                    ghostClass="ar-sortable__drag-area"
                    list={items}
                    setList={setItems}
                    onChange={() => setIsLandingPageUpdated(true)}
                    disabled={showComment}
                  >
                    {items.map((item, itemIndex) => (
                      <Fragment key={item.pageComponentId}>
                        {activePopupIndex === itemIndex && showComment && (
                          <Button
                            type="button"
                            className="ar-btn btn-danger ar-btn--exit-comment-mode"
                            onClick={() => exitComment(itemIndex)}
                          >
                            Exit Comment Mode
                          </Button>
                        )}
                        <div
                          className="ar-edit-page__wrapper"
                          key={item.pageComponentId}
                        >
                          <div className="ar-edit-page__badges">
                            {item.isInRealm ? (
                              <span className="badge badge-pill badge-success realm-badge">
                                Realm
                              </span>
                            ) : null}
                            {item.isRevised && (
                              <>
                                <span className="badge badge-pill badge-success">
                                  Revision
                                </span>
                              </>
                            )}
                            {item.isReference && (
                              <span className="badge badge-pill badge-success">
                                Reference
                              </span>
                            )}
                          </div>
                          <div
                            style={{ display: item.hidden ? 'none' : 'grid' }}
                            className="ar-edit-page__details"
                          >
                            <div className="ar-edit-page__project">
                              <span>{item.project}</span>
                              <span>{item.page} </span>
                            </div>
                            <div className="ar-edit-page__actions text-right">
                              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                              <button
                                id={`comment-button-${item.id}-${itemIndex}`}
                                type="button"
                                className={`ar-btn ar-btn--primary ar-btn--primary--comment ${
                                  item.comments.length > 0 ? 'count' : ''
                                } ar-btn--icon-rounded`}
                                onClick={() =>
                                  toggleContentVisibility(itemIndex)
                                }
                                disabled={showComment}
                              >
                                {item.comments.length > 0
                                  ? item.comments.length
                                  : ''}
                              </button>
                              <UncontrolledTooltip
                                placement="bottom"
                                target={`comment-button-${item.id}-${itemIndex}`}
                              >
                                Comment
                              </UncontrolledTooltip>
                              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                              <button
                                id={`revision-button-${item.id}-${itemIndex}`}
                                type="button"
                                className="ar-btn ar-btn--primary ar-btn--primary--revision ar-btn--icon-rounded"
                                onClick={() => {
                                  createTempComponent(item, itemIndex + 1);
                                }}
                                disabled={
                                  showComment ||
                                  item.isReference ||
                                  (!item.isRevised &&
                                    isActionDisable.revisionAction)
                                }
                              />
                              <UncontrolledTooltip
                                placement="bottom"
                                target={`revision-button-${item.id}-${itemIndex}`}
                                trigger="hover"
                              >
                                {item.isRevised ? 'View' : 'Add'} Revision
                              </UncontrolledTooltip>
                              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                              <button
                                id={`duplicate-button-${item.id}-${itemIndex}`}
                                type="button"
                                className="ar-btn ar-btn--secondary ar-btn--secondary--duplicate ar-btn--icon-rounded"
                                onClick={() => handleDuplicate(item)}
                                disabled={
                                  showComment ||
                                  isActionDisable.manageSectionAction
                                }
                              />
                              <UncontrolledTooltip
                                placement="bottom"
                                target={`duplicate-button-${item.id}-${itemIndex}`}
                                trigger="hover"
                              >
                                Duplicate Component
                              </UncontrolledTooltip>
                              <button
                                id={`remove-button-${item.id}-${itemIndex}`}
                                type="button"
                                className="btn btn-danger rounded-circle"
                                onClick={() => handleRemove(itemIndex)}
                                disabled={
                                  showComment || isActionDisable.deleteAction
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                              <UncontrolledTooltip
                                placement="bottom"
                                target={`remove-button-${item.id}-${itemIndex}`}
                                trigger="hover"
                              >
                                Remove Component
                              </UncontrolledTooltip>

                              <div className="ar-edit-page__actions__move d-flex ml-2">
                                <button
                                  id={`move-up-button-${item.id}`}
                                  type="button"
                                  className="ar-btn ar-btn--primary rounded-circle"
                                  onClick={() => handleMoveUp(item)}
                                  disabled={showComment}
                                >
                                  <FontAwesomeIcon icon={faArrowUp} />
                                </button>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={`move-up-button-${item.id}`}
                                  trigger="hover"
                                >
                                  Move Up
                                </UncontrolledTooltip>

                                <button
                                  id={`move-down-button-${item.id}`}
                                  type="button"
                                  className="ar-btn ar-btn--primary rounded-circle"
                                  onClick={() => handleMoveDown(item)}
                                  disabled={showComment}
                                >
                                  <FontAwesomeIcon icon={faArrowDown} />
                                </button>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={`move-down-button-${item.id}`}
                                  trigger="hover"
                                >
                                  Move Down
                                </UncontrolledTooltip>

                                <button
                                  id={`drag-screen-button-${item.id}-${itemIndex}`}
                                  type="button"
                                  className="ar-btn ar-btn--light ar-btn--light-drag rounded-circle"
                                  aria-label="Drag"
                                  disabled={showComment}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={`drag-screen-button-${item.id}-${itemIndex}`}
                                  trigger="hover"
                                >
                                  Drag and Drop
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </div>
                          {(presentationModeIsDesign &&
                            (item.designImage || item.design_image)) ||
                          (!presentationModeIsDesign &&
                            (item.wireFrameImage || item.wire_frame_image)) ? (
                            <>
                              <img
                                src={`${s3BucketUrl}/${
                                  presentationModeIsDesign
                                    ? formatImageUrl(item.designImage) ||
                                      formatImageUrl(item.design_image)
                                    : formatImageUrl(item.wireFrameImage) ||
                                      formatImageUrl(item.wire_frame_image)
                                }`}
                                alt="default component"
                                className="cover"
                                onClick={(evt) => {
                                  showPopup(evt, itemIndex);
                                }}
                                onMouseMove={handleMouseMove}
                                onMouseLeave={handleMouseOut}
                              />
                              <span
                                key={item.pageComponentId}
                                className="tooltip ar-common-tooltip"
                                style={{
                                  left: tooltipPosition.x,
                                  top: tooltipPosition.y,
                                  opacity: tooltipPosition.opacity,
                                  zIndex: tooltipPosition.zIndex,
                                }}
                              >
                                Click to leave a comment
                              </span>
                            </>
                          ) : (
                            <img
                              src={defaultComponentCover}
                              alt="default component"
                              className="cover"
                            />
                          )}

                          {activePopupIndex === itemIndex && showComment && (
                            <>
                              {labels.length &&
                                labels.map(
                                  (labelItem: LabelPosition, index) => (
                                    <div
                                      className="ar-comment-box-label"
                                      style={{
                                        position: 'absolute',
                                        left: labelItem.left,
                                        top: labelItem.top,
                                      }}
                                      onClick={() =>
                                        showLabelPopup(labelItem, index + 1)
                                      }
                                    >
                                      {index + 1}
                                    </div>
                                  ),
                                )}
                              <div
                                className="ar-comment-box"
                                style={popupPosition}
                              >
                                {(isNewComment || isUpdateComment) && (
                                  <>
                                    <div className="ar-comment-box-label">
                                      {isUpdateComment
                                        ? activeCommentIndex
                                        : labels.length + 1}
                                    </div>

                                    <div
                                      className="ar-comment-box-close"
                                      onClick={hidePopup}
                                    />
                                    <form>
                                      <Row>
                                        <Col md="12">
                                          <div className="ar-common-form__group form-group text-area">
                                            <Label htmlFor="comment">
                                              Leave a Comment
                                            </Label>
                                            <Input
                                              type="textarea"
                                              id="comment"
                                              className="text-area"
                                              // placeholder="Add your thoughts. @metion to notify someone."
                                              placeholder="Add your thoughts."
                                              onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>,
                                              ): void => {
                                                setComment(e.target.value);
                                                setPopupPosition({
                                                  ...popupPosition,
                                                  comment: e.target.value,
                                                });
                                              }}
                                              value={
                                                popupPosition.comment
                                                  ? popupPosition.comment
                                                  : comment
                                              }
                                            />
                                          </div>
                                        </Col>
                                        <Col md="12">
                                          {isNewComment && (
                                            <Button
                                              type="button"
                                              className="ar-btn ar-btn--ternary"
                                              onClick={() =>
                                                addComment(item.pageComponentId)
                                              }
                                            >
                                              Submit
                                            </Button>
                                          )}
                                          {isUpdateComment && (
                                            <Button
                                              type="button"
                                              className="ar-btn ar-btn--ternary"
                                              onClick={() =>
                                                editComment(
                                                  // eslint-disable-next-line no-underscore-dangle
                                                  popupPosition._id,
                                                  item.pageComponentId,
                                                )
                                              }
                                            >
                                              Update
                                            </Button>
                                          )}
                                        </Col>
                                      </Row>
                                    </form>
                                  </>
                                )}
                                {popupPosition.comment &&
                                  !isUpdateComment &&
                                  !isNewComment && (
                                    <div className="ar-comment-box-commented">
                                      <div className="ar-comment-box-commented-details">
                                        <div className="ar-comment-box-commented-profile">
                                          <div className="ar-comment-box-commented-profile-image">
                                            <img
                                              src={popupPosition.user?.picture}
                                              alt="profile"
                                              className="img-fluid"
                                            />
                                          </div>
                                          <div className="ar-comment-box-commented-profile-details">
                                            <div className="ar-comment-box-commented-profile-name">{`${popupPosition.user?.first_name} ${popupPosition.user?.last_name}`}</div>
                                            <div className="ar-comment-box-commented-profile-time">
                                              {moment(
                                                popupPosition.created_at,
                                              ).fromNow()}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="ar-comment-box-actions">
                                          <Button
                                            id="edit-button"
                                            className="ar-btn ar-btn--primary-edit"
                                            color="link"
                                            onClick={() =>
                                              setIsUpdateComment(true)
                                            }
                                          />
                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target="edit-button"
                                          >
                                            Edit
                                          </UncontrolledTooltip>
                                          <Button
                                            id="delete-button"
                                            className="ar-btn ar-btn--primary-delete"
                                            color="link"
                                            onClick={() => deleteComment()}
                                          />
                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target="delete-button"
                                          >
                                            Delete
                                          </UncontrolledTooltip>
                                        </div>
                                      </div>
                                      <div className="ar-comment-box-commented-content">
                                        <p>{popupPosition.comment}</p>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </>
                          )}

                          {showComment ||
                            (!isActionDisable.manageSectionAction && (
                              <div className="ar-edit-page__add-after">
                                <Button
                                  id={`add-after-button-${item.id}`}
                                  type="button"
                                  className="ar-btn ar-btn--primary rounded-circle"
                                  onClick={() => {
                                    setTempComponentIdList([]);
                                    setAddSectionIndex(itemIndex);
                                    toggle(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </Button>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={`add-after-button-${item.id}`}
                                >
                                  Add Section Here
                                </UncontrolledTooltip>
                              </div>
                            ))}
                        </div>

                        {/* Moodboard references modal */}
                        {/* eslint-disable-next-line no-underscore-dangle */}
                        {selectedItem === (item.id || item._id) && (
                          <div>
                            <MoodboardPopup
                              item={item}
                              prototypeId={prototypeId}
                              setSelectedItem={setSelectedItem}
                              formatImageUrl={formatImageUrl}
                              presentationModeIsDesign={
                                presentationModeIsDesign
                              }
                              s3BucketUrl={s3BucketUrl}
                              defaultComponentCover={defaultComponentCover}
                            />
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </ReactSortable>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Modal
          className="ar-edit-page__moodboard-popup ar-common-popup"
          isOpen={isOpen}
          toggle={() => toggle()}
          size="xl"
        >
          <ModalBody className="ar-common-popup__body">
            <PrototypePreview
              addedComponentList={addedComponentList}
              setAddedComponentList={setAddedComponentList}
              setIsFormUpdated={setIsFormUpdated}
              scrollIndex={scrollIndex}
              setScrollIndex={setScrollIndex}
            />
            <AddSectionsPopup
              setItems={(e: any) => onItemSelect(e)}
              addedComponentList={addedComponentList}
              saveData={saveData}
              toggle={toggle}
              isFormUpdated={isFormUpdated}
              project={location.state.prototypeName}
              page={pageName}
            />
          </ModalBody>
        </Modal>
      </div>
      {items.length === 0 && emptyPage && (
        <div className="ar-common-page__empty">
          <div className="ar-edit-page--empty text-center">
            <h2 className="text-secondary">{pageName}</h2>
            <h3>Let’s start build the page!</h3>
            <p>
              Once you start adding new sections, you will see your page build
              here.
            </p>
            <button
              type="button"
              className="ar-btn ar-btn-outline--light rounded-pill mx-auto"
              onClick={() => toggle()}
            >
              Add new sections
            </button>
          </div>
        </div>
      )}
      {/* eslint-disable @typescript-eslint/no-unused-vars, consistent-return */}
      {isLandingPageUpdated && (
        <NavigationPrompt
          when={(crntLocation, nextLocation, _action) =>
            !nextLocation ||
            !nextLocation.pathname.startsWith(crntLocation.pathname)
          }
        >
          {({ onCancel, onConfirm }) => {
            if (isLandingPageUpdated) {
              return (
                <Alert
                  onConfirm={() => {
                    setIsLandingPageUpdated(false);
                    onConfirm();
                  }}
                  onCancel={onCancel}
                  open
                />
              );
            }

            setIsLandingPageUpdated(false);
            onConfirm();
          }}
        </NavigationPrompt>
      )}

      {leaveAlertOpen && (
        <Alert
          onConfirm={() => {
            setIsRevisionUpdated(false);
            setLeaveAlertOpen(false);
            removeTempComponent();
          }}
          onCancel={() => setLeaveAlertOpen(false)}
          open
        />
      )}

      {/* REVISION MODAL */}
      <Modal
        className="ar-edit-page__moodboard-popup"
        isOpen={revisionModal}
        revisionToggle={() =>
          isRevisionUpdated ? setLeaveAlertOpen(true) : removeTempComponent()
        }
        size="xl"
      >
        <ModalHeader className="ar-edit-page__moodboard-popup-title">
          <div className="container-fluid">
            <Row>
              <Col>Create revision</Col>
            </Row>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="ar-revision-popup">
            <div className="ar-revision-popup-image">
              <p className="ar-revision-popup-sub-heading">Original Design</p>
              <div className="ar-revision-popup-image-wrapper">
                {(presentationModeIsDesign &&
                  (selectedRevisionItem?.designImage ||
                    selectedRevisionItem?.design_image)) ||
                (!presentationModeIsDesign &&
                  (selectedRevisionItem?.wireFrameImage ||
                    selectedRevisionItem?.wire_frame_image)) ? (
                  <img
                    src={`${s3BucketUrl}/${
                      presentationModeIsDesign
                        ? formatImageUrl(selectedRevisionItem?.designImage) ||
                          formatImageUrl(selectedRevisionItem?.design_image)
                        : formatImageUrl(
                            selectedRevisionItem?.wireFrameImage,
                          ) ||
                          formatImageUrl(selectedRevisionItem?.wire_frame_image)
                    }`}
                    alt="Original Design"
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src={defaultComponentCover}
                    alt="Original Design"
                    className="img-fluid"
                  />
                )}
                <div className="ar-revision-popup-image-action">
                  <p>Sketch file</p>
                  <Button
                    type="button"
                    className="ar-btn ar-btn-outline--light"
                    // eslint-disable-next-line no-return-assign
                    onClick={() =>
                      selectedRevisionItem?.wireFrameSketch
                        ? (window.location.href = `${s3BucketUrl}/${selectedRevisionItem?.wireFrameSketch}`)
                        : ''
                    }
                    disabled={!selectedRevisionItem?.wireFrameSketch}
                  >
                    Download
                  </Button>
                </div>
              </div>
            </div>
            <div className="ar-revision-popup-add-files">
              <p className="ar-revision-popup-sub-heading">
                Add revision files
              </p>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={() => {
                  handleRevisionSubmit();
                }}
                validationSchema={validationSchema}
                innerRef={formRef}
              >
                {(formik) => {
                  const { values } = formik;

                  return (
                    <form>
                      <Row>
                        <Col xs="12" md="6">
                          <DragAndDropField
                            previewStatus
                            uploading={isFileUploading.wireframeImage}
                            currentFileName={
                              values.wireFrameImage ? values.wireFrameImage : ''
                            }
                            name="wireFrameImage"
                            label="Wire-frame Image*"
                            validFileTypes={['png', 'jpeg', 'jpg']}
                            fileType="wireFrameImage"
                            handleUploadFile={(event: any) =>
                              handleUploadFile(event, 'wireFrameImage', values)
                            }
                          />
                          <ErrorMessage name="wireFrameImage">
                            {(msg) => (
                              <div className="error" style={{ marginTop: -25 }}>
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </Col>
                        <Col xs="12" md="6">
                          <DragAndDropField
                            uploading={isFileUploading.wireFrameSketch}
                            currentFileName={
                              values.wireFrameSketch
                                ? values.wireFrameSketch
                                : ''
                            }
                            name="wireFrameSketch"
                            label="Wire-frame Sketch"
                            validFileTypes={['sketch']}
                            fileType="wireFrameSketch"
                            handleUploadFile={(event: any) =>
                              handleUploadFile(event, 'wireFrameSketch', values)
                            }
                          />
                          <ErrorMessage name="wireFrameSketch">
                            {(msg) => (
                              <div className="error" style={{ marginTop: -25 }}>
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row className="w-100">
            <Col xs="12" className="d-flex justify-content-end">
              <Button
                type="button"
                className="ar-btn ar-btn--light btn btn-secondary mr-3"
                onClick={() =>
                  isRevisionUpdated
                    ? setLeaveAlertOpen(true)
                    : removeTempComponent()
                }
              >
                Back
              </Button>
              <Button
                type="button"
                className="ar-btn ar-btn--ternary"
                onClick={() => handleRevisionSubmitRef()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>

      {/* VIEW/DELETE REVISION */}
      <Modal
        className="ar-edit-page__moodboard-popup"
        isOpen={revisionViewModal}
        revisionToggle={revisionViewToggle}
        size="xl"
      >
        <ModalHeader className="ar-edit-page__moodboard-popup-title">
          <div className="container-fluid">
            <Row>
              <Col>Revision Details</Col>
            </Row>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="ar-revision-popup">
            <div className="ar-revision-popup-image">
              <p className="ar-revision-popup-sub-heading">
                Original Design
                <span className="ar-revision-popup-sub-heading-data">
                  Added date:{' '}
                  {moment(revisionItem?.originalComponent?.created_at).format(
                    'MM/DD/YYYY',
                  )}
                </span>
              </p>
              <div className="ar-revision-popup-image-wrapper">
                {(presentationModeIsDesign &&
                  (revisionItem?.originalComponent?.designImage ||
                    revisionItem?.originalComponent?.design_image)) ||
                (!presentationModeIsDesign &&
                  (revisionItem?.originalComponent?.wireFrameImage ||
                    revisionItem?.originalComponent?.wire_frame_image)) ? (
                  <img
                    src={`${s3BucketUrl}/${
                      presentationModeIsDesign
                        ? formatImageUrl(
                            revisionItem?.originalComponent?.designImage,
                          ) ||
                          formatImageUrl(
                            revisionItem?.originalComponent?.design_image,
                          )
                        : formatImageUrl(
                            revisionItem?.originalComponent?.wireFrameImage,
                          ) ||
                          formatImageUrl(
                            revisionItem?.originalComponent?.wire_frame_image,
                          )
                    }`}
                    alt="Original Design"
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src={defaultComponentCover}
                    alt="Original Design"
                    className="img-fluid"
                  />
                )}
              </div>
            </div>
            <div className="ar-revision-popup-image">
              <p className="ar-revision-popup-sub-heading">
                Revision
                <span className="ar-revision-popup-sub-heading-data">
                  Added date:{' '}
                  {moment(revisionItem?.revisionComponent?.created_at).format(
                    'MM/DD/YYYY',
                  )}
                </span>
              </p>
              <div className="ar-revision-popup-image-wrapper">
                {(presentationModeIsDesign &&
                  (revisionItem?.revisionComponent?.designImage ||
                    revisionItem?.revisionComponent?.design_image)) ||
                (!presentationModeIsDesign &&
                  (revisionItem?.revisionComponent?.wireFrameImage ||
                    revisionItem?.revisionComponent?.wire_frame_image)) ? (
                  <img
                    src={`${s3BucketUrl}/${
                      presentationModeIsDesign
                        ? formatImageUrl(
                            revisionItem?.revisionComponent?.designImage,
                          ) ||
                          formatImageUrl(
                            revisionItem?.revisionComponent?.design_image,
                          )
                        : formatImageUrl(
                            revisionItem?.revisionComponent?.wireFrameImage,
                          ) ||
                          formatImageUrl(
                            revisionItem?.revisionComponent?.wire_frame_image,
                          )
                    }`}
                    alt="Original Design"
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src={defaultComponentCover}
                    alt="Original Design"
                    className="img-fluid"
                  />
                )}
                <div className="ar-revision-popup-image-action">
                  <p>Delete Revision</p>
                  <Button
                    type="button"
                    className="ar-btn ar-btn-outline--light"
                    onClick={() => deleteRevision()}
                    disabled={isActionDisable.revisionAction}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row className="w-100">
            <Col xs="12" className="d-flex justify-content-end">
              <Button
                type="button"
                className="ar-btn ar-btn--ternary"
                onClick={revisionViewToggle}
              >
                Done
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditPage;
