import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Button } from 'reactstrap';
import { getSanitizedS3BucketUrl } from '../../helpers';

const DragAndDropField: React.FC<any> = (props) => {
  const {
    label,
    name,
    fileType,
    handleUploadFile,
    validFileTypes,
    currentFileName = '',
    uploading = false,
    previewStatus = false,
  } = props;
  const [currentUpload, setCurrentUpload] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [file, setFile] = useState<{
    name: string;
  }>({
    name: '',
  });
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const s3BucketUrl = getSanitizedS3BucketUrl();
  const formatImageUrl = (url: string) =>
    url && url[0] === '/' ? url.substring(1, url.length) : url;

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    currentFileName &&
      setImagePreview(`${s3BucketUrl}/${formatImageUrl(currentFileName)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFileName]);

  const handleChangeFileUpload = (uploadedFile: File) => {
    setIsUploaded(false);
    setFile(uploadedFile);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result as string);
    };
    reader.readAsDataURL(uploadedFile);
  };

  const handleButton = async () => {
    if (file.name) {
      setCurrentUpload(true);
      await handleUploadFile(file, fileType);
      setCurrentUpload(false);
      setIsUploaded(true);
    } else {
      setIsEmpty(true);
    }
  };

  const onSelect = () => {
    setIsUploaded(false);
    setIsEmpty(false);
  };

  const onDrop = () => {
    setIsUploaded(false);
    setIsEmpty(false);
  };

  let fieldLabel = label;
  if (uploading && currentUpload) {
    fieldLabel = `${label} is uploading...`;
  }
  if (file.name !== '' && !uploading && !isUploaded) {
    fieldLabel = `${label} is ready to upload. Click "Upload" to proceed`;
  }

  return (
    <div className="form-group">
      <label htmlFor={name}>{fieldLabel}</label>
      <div className="ar-preview-img">
        {previewStatus && imagePreview && (
          <div className="ar-preview-img-wrapper">
            <img src={imagePreview} alt="Preview" className="img-fluid" />
          </div>
        )}
        <FileUploader
          handleChange={handleChangeFileUpload}
          name="file"
          types={validFileTypes}
          disabled={uploading && currentUpload}
          onSelect={onSelect}
          onDrop={onDrop}
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="form-group__hint">
        {file.name
          ? `${file.name}`
          : currentFileName || 'No files uploaded yet'}
      </label>
      {isEmpty && <p className="error">Please select or drop a file first</p>}
      {file.name !== '' && !isUploaded && (
        <Button
          className="btn-sm float-right mr-3"
          disabled={uploading && currentUpload}
          onClick={handleButton}
        >
          Upload
        </Button>
      )}
    </div>
  );
};

export default DragAndDropField;
